import { default as uuid } from 'uuid/v4';

import { categoryTypes as types } from '../actions/types';
import { IUpdateCategoryAction } from '../actions/categoryActions';

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case types.ADD_CATEGORY:
      const categories =
        action.payload.position !== null
          ? [
              ...state.categories.slice(0, action.payload.position - 1),
              { id: uuid() },
              ...state.categories.slice(action.payload.position - 1),
            ]
          : [...state.categories, { id: uuid() }];
      return {
        ...state,
        categories,
      };
    case types.UPDATE_CATEGORY:
      return {
        ...state,
        categories: [
          ...state.categories.slice(0, (<IUpdateCategoryAction>action).payload.categoryIndex),
          {
            ...state.categories[(<IUpdateCategoryAction>action).payload.categoryIndex],
            ...(<IUpdateCategoryAction>action).payload.values,
          },
          ...state.categories.slice((<IUpdateCategoryAction>action).payload.categoryIndex + 1),
        ],
      };
    case types.DELETE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter(
          (category: any, index: any) => index !== action.payload.categoryIndex,
        ),
      };
    default:
      throw new Error();
  }
};

export interface Category {
  id: string;
  name?: string;
}

export interface CategoryListState {
  categories: Category[];
}

export default reducer;
