import React, { useContext, useState } from 'react';

import {
  Button,
  Card,
  Checkbox,
  Col,
  Icon,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Table,
  Typography,
} from 'antd';
import { CSVLink } from 'react-csv';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';

import generalMessages from '../../messages';

import './ProductBlock.css';
import { DynamicsNavContext } from '../../dynamics-nav-utility/dynamicsNavProvider';
import {
  generateDynamicsNavTableColumns,
  getWebExportKeys,
} from '../../dynamics-nav-utility/dynamicsNavUtil';

const { Text } = Typography;

interface Props extends InjectedIntlProps {
  isDisabled: boolean;
  isDone: boolean;
  onReset: ((e?: React.MouseEvent<any, MouseEvent> | undefined) => void) | undefined;
  onDone: ((e?: React.MouseEvent<any, MouseEvent> | undefined) => void) | undefined;
  onRestart: ((e?: React.MouseEvent<any, MouseEvent> | undefined) => void) | undefined;
  postData: any;
  responseData: any;
  configName: string;
}

const ProductBlock = ({
  isDisabled,
  isDone,
  postData,
  responseData,
  onReset,
  onRestart,
  configName,
  onDone,
  intl,
}: Props) => {
  const dynamicNav = useContext(DynamicsNavContext);
  const tableColumns = generateDynamicsNavTableColumns(dynamicNav.elements);
  // ! SPECIAL CASES FOR TABLE COLUMNS IN THIS COMPONENT !
  tableColumns.map(el => {
    if (el.key === 'F_80004') {
      return {
        title: 'DEALER PRICE',
        dataIndex: 'F_80004',
        key: 'F_80004',
        render: (text: any) => {
          return <div style={{ backgroundColor: 'red', color: 'white' }}>{text}</div>;
        },
      };
    }
    return el;
  });

  const [checkBoxes, setCheckBoxes] = useState(
    tableColumns.map((column: any) => {
      return {
        ...column,
        checked: true,
      };
    }),
  );

  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  const onChangeCheckbox = (index: any, checked: any) => {
    setCheckBoxes([
      ...checkBoxes.slice(0, index),
      {
        ...checkBoxes[index],
        checked,
      },
      ...checkBoxes.slice(index + 1),
    ]);
  };

  const selectAll = () => {
    setCheckBoxes(
      checkBoxes.map((cB: any) => {
        return {
          ...cB,
          checked: true,
        };
      }),
    );
  };

  const unSelectAll = () => {
    setCheckBoxes(
      checkBoxes.map((cB: any) => {
        return {
          ...cB,
          checked: false,
        };
      }),
    );
  };

  const selectWebKeys = () => {
    setCheckBoxes(
      checkBoxes.map((cB: any) => {
        return {
          ...cB,
          checked: getWebExportKeys(dynamicNav.elements).includes(cB.key),
        };
      }),
    );
  };

  const csvProducts = responseData.products.map((product: any) => {
    const productKeys = Object.keys(product).filter((productKey: any) =>
      checkBoxes.find((cB: any) => cB.key === productKey && cB.checked),
    );
    return productKeys.reduce((element: any, key: any) => {
      return {
        ...element,
        [key]: product[key],
      };
    }, {});
  });

  const csvKeys =
    responseData.products && responseData.products.length > 0
      ? ['0'].map(() => {
          const productKeys = Object.keys(responseData.products[0]).filter((productKey: any) =>
            checkBoxes.find((cB: any) => cB.key === productKey && cB.checked),
          );
          return productKeys.reduce((element: any, key: any) => {
            return {
              ...element,
              [key]: dynamicNav.elements.find(el => el.id === key)!.desc,
            };
          }, {});
        })
      : [];
  return (
    <Row>
      <Col>
        <Card
          size="small"
          title={
            <Text disabled={isDisabled}>
              <FormattedMessage {...generalMessages.generateCombinations} />{' '}
              {isDone && <Icon theme="filled" type="check-circle" style={{ color: '#52c41a' }} />}
            </Text>
          }
          extra={
            (isDisabled && !isDone && (
              <Text disabled={isDisabled}>
                <FormattedMessage {...generalMessages.dynamicsNavExportFirst} />
              </Text>
            )) ||
            (isDone && (
              <Popconfirm
                title={
                  <FormattedMessage
                    {...generalMessages.areYouSureYouWantToGenerateCombinationsAgain}
                  />
                }
                icon={null}
                okText={<FormattedMessage {...generalMessages.yes} />}
                cancelText={<FormattedMessage {...generalMessages.cancel} />}
                onConfirm={onReset}
                placement="bottomRight"
              >
                <a href="#">
                  <FormattedMessage {...generalMessages.generateCombinationsAgain} />
                </a>
              </Popconfirm>
            ))
          }
        >
          {postData !== null && (
            <div style={{ padding: '10px', textAlign: 'center' }}>
              <Spin />
            </div>
          )}
          {responseData.products && responseData.products.length > 0 && postData === null && (
            <Table
              // scroll={{ x: true }}
              className="table-products"
              scroll={{ x: 'max-content' }}
              pagination={{ pageSize: 25 }}
              size="small"
              dataSource={responseData.products}
              columns={tableColumns}
            />
          )}
          <Text disabled={isDisabled} />
          {!isDisabled && responseData.products && responseData.products.length > 0 && (
            <Button onClick={() => setOpenDownloadModal(true)}>
              <FormattedMessage {...generalMessages.downloadCsv} />
            </Button>
          )}
          <Button
            style={{ marginLeft: '10px' }}
            type="primary"
            disabled={isDisabled}
            onClick={onRestart}
          >
            <FormattedMessage {...generalMessages.end} />
          </Button>
          <Modal
            visible={openDownloadModal}
            footer={[
              <Button
                onClick={() => setOpenDownloadModal(false)}
                key="back"
                style={{ marginRight: 10 }}
              >
                Sluiten
              </Button>,
              <CSVLink
                separator={']'}
                enclosingCharacter={``}
                className="ant-btn ant-btn-primary"
                data={[...csvKeys, ...csvProducts]}
                filename={`${configName}_export.csv`}
              >
                <FormattedMessage {...generalMessages.downloadCsv} />
              </CSVLink>,
            ]}
            style={{ top: 20 }}
            width="800px"
          >
            <div>
              <div style={{ borderBottom: '1px solid #e9e9e9', paddingBottom: 10 }}>
                <Row>
                  <Col span={12}>
                    <Button style={{ marginRight: 10 }} onClick={selectAll}>
                      Alles selecteren
                    </Button>
                    <Button onClick={selectWebKeys}>Website selectie</Button>
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <Button style={{ marginRight: 20 }} onClick={unSelectAll}>
                      Verwijder selectie
                    </Button>
                  </Col>
                </Row>
              </div>
              <br />
              <Row>
                {checkBoxes.map((element: any, index: number) => {
                  return (
                    <Col key={element.key} span={12}>
                      <Checkbox
                        value={element.key}
                        onChange={(e: any) => onChangeCheckbox(index, e.target.checked)}
                        checked={element.checked}
                      >
                        {element.title}
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Modal>
        </Card>
      </Col>
    </Row>
  );
};

export default injectIntl(ProductBlock);
