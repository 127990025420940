import { generateDescriptions } from '../../utils/descriptions';
import { appTypes as types } from '../actions/types';

export interface AppState {
  name: string;
  descriptions: any;
}

const reducer = (state: AppState, action: any) => {
  switch (action.type) {
    case types.SET_APP_STATE:
      const {
        id,
        data: { name, categories, tags, descriptions, inExport2, inExport, type },
      } = action.payload;

      const desc =
        (descriptions && generateDescriptions(descriptions, categories)) || state.descriptions;

      return {
        ...state,
        id,
        name,
        categories,
        descriptions: desc,
        type,
        tags: tags || [],
        inExport2,
        inExport,
      };
    case types.RESET_APP_STATE:
      return {
        ...state,
        name: undefined,
        id: undefined,
        descriptions: {
          line1: [],
          line2: [],
          line3: [],
          line4: [],
          line5: [],
        },
        tags: [],
        categories: [],
      };
    default:
      throw new Error();
  }
};

export default reducer;
