import { codeTypes as types } from './types';

export interface IAddCodeAction {
  type: typeof types.ADD_CODE;
  payload: {
    categoryIndex: number;
  };
}

export interface IUpdateCodeAction {
  type: typeof types.UPDATE_CODE;
  payload: {
    categoryIndex: number;
    codeIndex: number;
    name: any;
  };
}

export interface IToggleActiveCodeAction {
  type: typeof types.UPDATE_CODE;
  payload: {
    categoryIndex: number;
    codeIndex: number;
  };
}

export interface IDuplicateCodeAction {
  type: typeof types.DUPLICATE_CODE;
  payload: {
    categoryIndex: number;
    codeIndex: number;
  };
}

export interface IDeleteCodeAction {
  type: typeof types.DELETE_CODE;
  payload: {
    categoryIndex: number;
    codeIndex: number;
  };
}

export type CodeActionTypes = IAddCodeAction | IUpdateCodeAction | IDeleteCodeAction;

export const addCode = ({ categoryIndex }: any): IAddCodeAction => ({
  type: types.ADD_CODE,
  payload: {
    categoryIndex,
  },
});

export const updateCode = ({ categoryIndex, codeIndex, name }: any): IUpdateCodeAction => ({
  type: types.UPDATE_CODE,
  payload: {
    categoryIndex,
    codeIndex,
    name,
  },
});

export const duplicateCode = ({ categoryIndex, codeIndex }: any): IDuplicateCodeAction => ({
  type: types.DUPLICATE_CODE,
  payload: {
    categoryIndex,
    codeIndex,
  },
});

export const deleteCode = ({ categoryIndex, codeIndex }: any): IDeleteCodeAction => ({
  type: types.DELETE_CODE,
  payload: {
    categoryIndex,
    codeIndex,
  },
});

export const toggleActiveCode = ({ categoryIndex, codeIndex }: any): IToggleActiveCodeAction => ({
  type: types.TOGGLE_ACTIVE_CODE,
  payload: {
    categoryIndex,
    codeIndex,
  },
});
