import { default as uuid } from 'uuid/v4';

import { codeTypes as types } from '../actions/types';
import {
  CodeActionTypes,
  IDeleteCodeAction,
  IUpdateCodeAction,
  IDuplicateCodeAction,
  IToggleActiveCodeAction,
} from '../actions/codeActions';

export interface Code {
  id: string;
  name?: string;
  properties?: any[];
  active?: boolean;
}

export interface CodeListState {
  codes: Code[];
}

const reducer = (state: CodeListState, action: CodeActionTypes) => {
  switch (action.type) {
    case types.ADD_CODE:
      return {
        ...state,
        codes: [...(state.codes || []), { id: uuid(), active: false }],
      };
    case types.UPDATE_CODE:
      return {
        ...state,
        codes: [
          ...state.codes.slice(0, (<IUpdateCodeAction>action).payload.codeIndex),
          {
            ...state.codes[(<IUpdateCodeAction>action).payload.codeIndex],
            name: (<IUpdateCodeAction>action).payload.name,
          },
          ...state.codes.slice((<IUpdateCodeAction>action).payload.codeIndex + 1),
        ],
      };
    case types.DUPLICATE_CODE:
      return {
        ...state,
        codes: [
          ...state.codes,
          {
            ...state.codes[(<IUpdateCodeAction>action).payload.codeIndex],
            id: uuid(),
            properties: (
              state.codes[(<IUpdateCodeAction>action).payload.codeIndex].properties || []
            ).map((property: any) => ({
              ...property,
              id: uuid(),
            })),
          },
        ],
      };
    case types.DELETE_CODE:
      return {
        ...state,
        codes: state.codes.filter(
          (code: any, index: any) => index !== (<IDeleteCodeAction>action).payload.codeIndex,
        ),
      };
    case types.TOGGLE_ACTIVE_CODE:
      return {
        ...state,
        codes: [
          ...state.codes.slice(0, (<IToggleActiveCodeAction>action).payload.codeIndex),
          {
            ...state.codes[(<IToggleActiveCodeAction>action).payload.codeIndex],
            active: !state.codes[(<IToggleActiveCodeAction>action).payload.codeIndex].active,
          },
          ...state.codes.slice((<IToggleActiveCodeAction>action).payload.codeIndex + 1),
        ],
      };
    default:
      throw new Error();
  }
};

export default reducer;
