import React, { useState, useContext, useEffect } from 'react';
import { FormattedMessage, InjectedIntl } from 'react-intl';
import axios from 'axios';

import { Button, Collapse, Icon, Input, Badge, Divider, Menu, Dropdown, message } from 'antd';

import {
  addCode,
  deleteCode,
  updateCode,
  duplicateCode,
  toggleActiveCode,
} from '../../../core/actions/codeActions';

import messages from '../messages';

import { default as styles } from './CodeList.module.css';
import { AppDispatch } from '../../../App';
import PropertyList from '../PropertyList/PropertyList';
import ButtonGroup from 'antd/lib/button/button-group';
import { apiUri } from '../../../config/vars';

type Props = {
  isDisabled: boolean;
  intl: InjectedIntl;
  codes?: any[];
  categoryIndex: number;
};

const CodeList = ({ isDisabled, intl, codes, categoryIndex }: Props) => {
  const dispatch = useContext(AppDispatch);

  const [activeCodeKey, setActiveCodeKey] = useState<string[] | string>([]);

  const [postPropertyLinkData, setPostPropertyLinkData] = useState(null);

  useEffect(() => {
    const postPropertyLink = async (data: any) => {
      try {
        await axios.post(`${apiUri}/properties`, JSON.stringify(data));
        message.success('Linken geslaagd');
      } catch (e) {
        message.error('Linken is niet gelukt');
      }

      setPostPropertyLinkData(null);
    };
    if (postPropertyLinkData) {
      postPropertyLink(postPropertyLinkData);
    }
  }, [postPropertyLinkData]);

  return (
    <>
      <Badge
        count={(codes && codes.length) || 0}
        style={{ backgroundColor: '#108ee9' }}
        offset={[15, 15]}
      >
        <ButtonGroup>
          <Button disabled={isDisabled} onClick={() => dispatch(addCode({ categoryIndex }))}>
            <Icon type="plus" />
            <FormattedMessage {...messages.codeAdd} />
          </Button>
          <Button icon="shrink" onClick={() => setActiveCodeKey([])} />
        </ButtonGroup>
      </Badge>
      <div className={styles.wrapper}>
        <Collapse
          activeKey={activeCodeKey}
          expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
          onChange={(keys: string | string[]) => {
            setActiveCodeKey(keys);
          }}
        >
          {codes!.map((code, index) => (
            <Collapse.Panel
              key={code.id}
              header={
                <Input
                  disabled={isDisabled}
                  placeholder={intl.formatMessage(messages.codeName)}
                  defaultValue={code.name}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  onChange={e => {
                    dispatch(
                      updateCode({
                        categoryIndex,
                        codeIndex: index,
                        name: e.target.value,
                      }),
                    );
                  }}
                />
              }
              extra={
                !isDisabled && (
                  <div style={{ display: 'flex', marginLeft: '10px' }}>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                        dispatch(
                          toggleActiveCode({
                            categoryIndex,
                            codeIndex: index,
                          }),
                        );
                      }}
                    >
                      <Badge status={code.active ? 'success' : 'default'} />
                    </div>
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item>
                            <a
                              onClick={e => {
                                e.stopPropagation();
                                dispatch(
                                  duplicateCode({
                                    categoryIndex,
                                    codeIndex: index,
                                  }),
                                );
                              }}
                            >
                              Dupliceren
                            </a>
                          </Menu.Item>
                          <Menu.Item disabled={!code.properties || code.properties.length < 2}>
                            <a
                              onClick={e => {
                                e.stopPropagation();
                                if (code.properties && code.properties.length >= 2) {
                                  setPostPropertyLinkData(code.properties);
                                }
                              }}
                            >
                              Linken
                            </a>
                          </Menu.Item>
                          <Menu.Item>
                            <a
                              onClick={e => {
                                e.stopPropagation();
                                dispatch(
                                  deleteCode({
                                    categoryIndex,
                                    codeIndex: index,
                                  }),
                                );
                              }}
                            >
                              Verwijderen
                            </a>
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <a
                        onClick={e => e.stopPropagation()}
                        // style={{ marginLeft: '10px' }}
                        className="ant-dropdown-link"
                      >
                        <Icon type="more" />
                      </a>
                    </Dropdown>
                  </div>
                )
              }
            >
              <PropertyList
                properties={code.properties}
                intl={intl}
                isDisabled={isDisabled}
                codeIndex={index}
                categoryIndex={categoryIndex}
              />
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
    </>
  );
};

CodeList.defaultProps = {
  codes: [],
};

export default CodeList;
