import {
  categoryTypes,
  codeTypes,
  propertyTypes,
  descriptionTypes,
  nameTypes,
  appTypes,
  tagTypes,
} from '../actions/types';
import categoryReducer from './categoryReducer';
import codeReducer from './codeReducer';
import propertyReducer from './propertyReducer';
import descriptionReducer from './descriptionReducer';
import nameReducer from './nameReducer';
import appReducer from './appReducer';
import tagReducer from './tagReducer';

const isCategoryCodeOrPropertyAction = (type: string) =>
  !!{
    ...categoryTypes,
    ...codeTypes,
    ...propertyTypes,
  }[type];

const isCodeOrPropertyAction = (type: string) =>
  !!{
    ...codeTypes,
    ...propertyTypes,
  }[type];

const isPropertyAction = (type: string) =>
  !!{
    ...propertyTypes,
  }[type];

const isDescriptionAction = (type: string) =>
  !!{
    ...descriptionTypes,
  }[type];

const isNameAction = (type: string) =>
  !!{
    ...nameTypes,
  }[type];

const isAppAction = (type: string) =>
  !!{
    ...appTypes,
  }[type];

const isTagAction = (type: string) =>
  !!{
    ...tagTypes,
  }[type];

const categoryRootReducer = (state: any, action: any) => {
  const { categoryIndex } = action.payload;
  if (isCodeOrPropertyAction(action.type)) {
    return {
      ...state,
      categories: [
        ...state.categories.slice(0, categoryIndex),
        codeRootReducer(state.categories[categoryIndex], action),
        ...state.categories.slice(categoryIndex + 1),
      ],
    };
  }

  return categoryReducer(state, action);
};

const codeRootReducer = (state: any, action: any) => {
  const { codeIndex } = action.payload;
  if (isPropertyAction(action.type)) {
    return {
      ...state,
      codes: [
        ...state.codes.slice(0, codeIndex),
        propertyReducer(state.codes[codeIndex], action),
        ...state.codes.slice(codeIndex + 1),
      ],
    };
  }

  return codeReducer(state, action);
};

const rootReducer = (state: any, action: any) => {
  if (isAppAction(action.type)) {
    return appReducer(state, action);
  }

  if (isTagAction(action.type)) {
    return tagReducer(state, action);
  }

  if (isCategoryCodeOrPropertyAction(action.type)) {
    return categoryRootReducer(state, action);
  }

  if (isDescriptionAction(action.type)) {
    return descriptionReducer(state, action);
  }

  if (isNameAction(action.type)) {
    return nameReducer(state, action);
  }

  return state;
};

export default rootReducer;
