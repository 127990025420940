import React from 'react';
import { Modal, Spin, Table, Popconfirm, Divider } from 'antd';
import { FormattedMessage } from 'react-intl';

import generalMessages from '../../messages';
import TagList from '../CategoryBlock/TagList';

const duplicateMap = (duplicates: any = [], entries: any) => {
  return duplicates.map((dupe: any) => {
    if (dupe.config.length > 1) {
      return {
        key: dupe.config.join('+'),
        config: `Meerdere configuraties`,
        count: dupe.codeCount
          ? dupe.codeCount === 10000
            ? '10000+'
            : dupe.codeCount
          : dupe.codes.length,
        actions: false,
        children: [
          ...dupe.config.map((config: any) => {
            const entry = entries.find((e: any) => e.id === config);
            return {
              key: [...dupe.config, config].join('+'),
              config: (entry && entry.data && entry.data.name) || `${config} (verwijderd)`,
              configId: entry && entry.id,
              data: entry && entry.data,
              count: '',
              actions: true,
            };
          }),
          {
            key: [...dupe.config, 'codes'].join('+'),
            showCodes: true,
            codes: [...dupe.codes.slice(0, 50), ...(dupe.codes.length > 50 ? ['...'] : [])],
          },
        ],
      };
    }

    const entry = entries.find((e: any) => e.id === dupe.config[0]);

    return {
      key: dupe.config[0],
      config: (entry && entry.data && entry.data.name) || `${dupe.config[0]} (verwijderd)`,
      configId: entry && entry.id,
      data: entry && entry.data,
      count: dupe.codeCount
        ? dupe.codeCount === 10000
          ? '10000+'
          : dupe.codeCount
        : dupe.codes.length,
      actions: true,
      children: [
        {
          key: dupe.config[0] + 'codes',
          showCodes: true,
          codes: [...dupe.codes.slice(0, 50), ...(dupe.codes.length > 50 ? ['...'] : [])],
        },
      ],
    };
  });
};

const getTableColumns = (onModify: any, onDelete: any) => {
  return [
    {
      title: 'Configuraties',
      dataIndex: 'config',
      key: 'config',
      width: '520px',
      render: (text: any, record: any) => {
        if (record.showCodes) {
          return (
            <span style={{ display: 'inline-block', marginTop: '-20px', paddingLeft: '45px' }}>
              {<TagList tags={record.codes || []} />}
            </span>
          );
        }
        return <span>{record.config}</span>;
      },
    },
    {
      title: '#',
      dataIndex: 'count',
      key: 'count',
      width: '150px',
    },
    {
      title: '',
      key: 'actions',
      width: '280px',
      render: (text: any, { configId: id, data }: any) => {
        if (!text.actions) {
          return null;
        }
        return (
          <span>
            <Popconfirm
              title="Ben je zeker dat je deze configuratie wilt aanpassen?"
              icon={null}
              okText={<FormattedMessage {...generalMessages.yes} />}
              cancelText={<FormattedMessage {...generalMessages.cancel} />}
              onConfirm={() => {
                onModify({ id, data });
              }}
              placement="bottomRight"
            >
              <a>Aanpassen</a>
            </Popconfirm>

            <Divider type="vertical" />
            <Popconfirm
              title="Ben je zeker dat je deze configuratie wilt verwijderen?"
              icon={null}
              okText={<FormattedMessage {...generalMessages.yes} />}
              cancelText={<FormattedMessage {...generalMessages.cancel} />}
              onConfirm={() => {
                onDelete(id);
              }}
              placement="bottomRight"
            >
              <a>Verwijderen</a>
            </Popconfirm>
          </span>
        );
      },
    },
  ];
};

type Props = {
  entries: any[];
  isOpen: boolean;
  setOpen: Function;
  duplicates: any[];
  onModify: Function;
  onDelete: Function;
  isLoading: boolean;
};

const DuplicateModal = ({
  entries,
  isOpen,
  setOpen,
  duplicates,
  onModify,
  onDelete,
  isLoading,
}: Props) => {
  const duplicateElements = duplicateMap(duplicates || [], entries || []);
  const tableColumns = getTableColumns(onModify, onDelete);
  return (
    <Modal
      // title={intl.formatMessage(messages.modalEntriesTitle)}
      title={'Duplicate combinaties'}
      visible={isOpen}
      // onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      footer={null}
      width="1200px"
    >
      {duplicates === null || entries === null ? (
        <div style={{ padding: '10px', textAlign: 'center' }}>
          <Spin />
        </div>
      ) : (
        <Table
          pagination={{ pageSize: 25 }}
          size="small"
          dataSource={duplicateElements}
          columns={tableColumns}
          loading={isLoading}
        />
      )}
    </Modal>
  );
};

export default DuplicateModal;
