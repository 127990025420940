import React, { useContext } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Spin, Button, Checkbox } from 'antd';
import axios from 'axios';

import './DynamicsNavIdsTab.module.css';
import { DynamicsNavContext } from '../../dynamics-nav-utility/dynamicsNavProvider';
import { DynamicsNavElement } from '../../dynamics-nav-utility/dynamicsNavUtil';
import { apiUri } from '../../config/vars';

const getColumns = (
  isEditing: Function,
  editingKey: string,
  cancel: Function,
  edit: Function,
  save: Function,
  deleteId: Function,
) => [
  {
    title: 'ID',
    dataIndex: 'id',
    // width: '25%',
    editable: true,
  },
  {
    title: 'Beschrijving',
    dataIndex: 'desc',
    // width: '45%',
    editable: true,
  },
  {
    title: 'Verplicht',
    dataIndex: 'isRequired',
    // width: '45%',
    type: 'boolean',
    editable: true,
    render: (text: any, record: any) => {
      const editable = isEditing(record);
      return <Checkbox disabled={!editable} checked={text} />;
    },
  },
  {
    title: 'Web export',
    dataIndex: 'inWebExport',
    // width: '45%',
    type: 'boolean',
    editable: true,
    render: (text: any, record: any) => {
      const editable = isEditing(record);
      return <Checkbox disabled={!editable} checked={text} />;
    },
  },
  {
    title: 'ES export',
    dataIndex: 'inESExport',
    // width: '45%',
    type: 'boolean',
    editable: true,
    render: (text: any, record: any) => {
      const editable = isEditing(record);
      return <Checkbox disabled={!editable} checked={text} />;
    },
  },
  {
    title: '',
    dataIndex: 'operation',
    render: (text: any, record: any) => {
      const editable = isEditing(record);
      return editable ? (
        <span>
          <EditableContext.Consumer>
            {(form: any) => (
              <a onClick={() => save(form, record.id)} style={{ marginRight: 8 }}>
                Opslaan
              </a>
            )}
          </EditableContext.Consumer>
          <a onClick={() => cancel(record.key)}>Annuleren</a>
        </span>
      ) : (
        <>
          {/* @ts-ignore */}
          <a onClick={() => editingKey === '' && edit(record.id)} style={{ paddingRight: '10px' }}>
            Aanpassen
          </a>
          <Popconfirm title="Bent u zeker?" onConfirm={() => deleteId(record.id)}>
            <a>Verwijderen</a>
          </Popconfirm>
        </>
      );
    },
  },
];

// @ts-ignore
const EditableContext = React.createContext();

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}: any) => {
  const getInput = () => {
    if (inputType === 'number') {
      return <InputNumber />;
    }
    if (inputType === 'boolean') {
      return <Checkbox />;
    }
    return <Input />;
  };

  const renderCell = ({ getFieldDecorator }: any) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
              ...(inputType === 'boolean' ? { valuePropName: 'checked' } : {}),
            })(getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  return <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>;
};

// TODO: Check if the keys get updated when there are changes (updateElements!)
const EditableTable = ({ form }: any) => {
  const dynamicNav = useContext(DynamicsNavContext);

  const [editingKey, setEditingKey] = React.useState('');
  // TODO: Check if this is still needed (maybe it's possible to use dynamicNav instead!)
  const [data, setData] = React.useState<undefined | DynamicsNavElement[]>(dynamicNav.elements);

  const isEditing = (record: any) => record.id === editingKey;

  const cancel = () => {
    setEditingKey('');
    setData((data || []).filter(d => d.id !== 'Dynamics Nav ID'));
  };

  const edit = (key: string) => {
    setEditingKey(key);
  };

  const deleteId = async (key: string) => {
    try {
      setEditingKey('');

      setData(d => d && d.filter(r => r.id !== key));
      await axios.delete(`${apiUri}/dynamicsnavids/${key}`);
      dynamicNav.updateElements && dynamicNav.updateElements();
    } catch (e) {}
  };

  const save = (form: any, key: any) => {
    form.validateFields(async (error: any, row: any) => {
      try {
        setEditingKey('');

        const index = data && data.findIndex(d => d.id === key);

        setData([...(data || []).slice(0, index), row, ...(data || []).slice(index! + 1)]);

        await axios.post(`${apiUri}/dynamicsnavids`, row);
        dynamicNav.updateElements && dynamicNav.updateElements();
      } catch (e) {}
    });
  };

  const handleAdd = () => {
    setData([{ id: 'Dynamics Nav ID', desc: 'Beschrijving' }, ...(data || [])]);
    setEditingKey('Dynamics Nav ID');
  };

  const components = {
    body: {
      cell: EditableCell,
    },
  };

  const columns = getColumns(isEditing, editingKey, cancel, edit, save, deleteId).map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.type || 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <EditableContext.Provider value={form}>
      {!data ? (
        <div style={{ padding: '10px', textAlign: 'center' }}>
          <Spin />
        </div>
      ) : (
        <div>
          <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
            Dynamics Nav Id toevoegen
          </Button>
          <Table
            size="small"
            rowKey="id"
            components={components}
            bordered
            dataSource={data}
            columns={columns}
            // @ts-ignore
            rowClassName="editable-row-dynamicsnavids"
            pagination={{
              pageSize: 25,
              onChange: cancel,
            }}
          />
        </div>
      )}
    </EditableContext.Provider>
  );
};

const DynamicsNavIdsTab = Form.create()(EditableTable);

export default DynamicsNavIdsTab;
