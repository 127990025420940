import { defineMessages } from 'react-intl';

const messages = defineMessages({
  areYouSureYouWantToEditCategories: {
    defaultMessage: 'Ben je zeker dat je categorieën wil aanpassen?',
    id: 'areYouSureYouWantToEditCategories',
  },
  categories: {
    defaultMessage: 'Configuratie',
    id: 'categories',
  },
  categoriesEdit: {
    defaultMessage: 'Categorieën aanpassen',
    id: 'categoriesEdit',
  },
  startFirst: {
    defaultMessage: 'De configuratie moet eerst gestart worden',
    id: 'startFirst',
  },
  categoryAdd: {
    defaultMessage: 'Categorie',
    id: 'category_add',
  },
  delete: {
    defaultMessage: 'Verwijderen',
    id: 'card_delete',
  },
  toDescriptions: {
    defaultMessage: 'Opslaan en naar omschrijvingen maken',
    id: 'descriptions',
  },
  codeAdd: {
    defaultMessage: 'Code',
    id: 'code_add',
  },
  propertyAdd: {
    defaultMessage: 'Eigenschap',
    id: 'property_add',
  },
  categoryName: {
    defaultMessage: 'Categorie',
    id: 'category_name',
  },
  codeName: {
    defaultMessage: 'Code',
    id: 'code_name',
  },
  propertyName: {
    defaultMessage: 'Waarde',
    id: 'property_name',
  },
  propertyAbbreviation: {
    defaultMessage: 'Afkorting',
    id: 'property_abbreviation',
  },
  propertyDynamicsNavId: {
    defaultMessage: 'Dynamics Nav ID',
    id: 'property_dynamics_nav_id',
  },
  totalCombinations: {
    defaultMessage: 'Totaal aantal combinaties: {total}',
    id: 'categories_total_combinations',
  },
  propertiesAutoCompleteTitle: {
    defaultMessage: 'Kies vorige eigenschappen',
    id: 'properties_autocomplete_title',
  },
  propertiesAutocompleteCancel: {
    defaultMessage: 'Annuleren',
    id: 'properties_autocomplete_cancel',
  },
  propertiesAutocompleteSubmit: {
    defaultMessage: 'Toevoegen',
    id: 'properties_autocomplete_submit',
  },
});

export default messages;
