import { default as uuid } from 'uuid/v4';

import { propertyTypes as types } from '../actions/types';

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case types.ADD_PROPERTY:
      return {
        ...state,
        properties: [...(state.properties || []), { id: uuid(), ...action.payload.values }],
      };
    case types.UPDATE_PROPERTY:
      const values = action.payload.values.condition
        ? {
            condition: {
              ...((state.properties[action.payload.propertyIndex] &&
                state.properties[action.payload.propertyIndex].condition) ||
                {}),
              ...action.payload.values.condition,
            },
          }
        : action.payload.values;
      return {
        ...state,
        properties: [
          ...state.properties.slice(0, action.payload.propertyIndex),
          {
            ...state.properties[action.payload.propertyIndex],
            ...values,
          },
          ...state.properties.slice(action.payload.propertyIndex + 1),
        ],
      };
    case types.DELETE_PROPERTY:
      return {
        ...state,
        properties: state.properties.filter(
          (property: any, index: any) => index !== action.payload.propertyIndex,
        ),
      };
    default:
      throw new Error();
  }
};

export default reducer;
