import { descriptionTypes as types } from '../actions/types';
import { generateDescriptions } from '../../utils/descriptions';

export interface Code {
  id: string;
  name?: string;
}

export interface DescriptionsState {
  descriptions: Code[];
}

const reducer = (state: DescriptionsState, action: any) => {
  switch (action.type) {
    case types.GENERATE_DESCRIPTIONS:
      const descriptions = generateDescriptions(state.descriptions, action.payload.categories);
      return {
        ...state,
        descriptions,
      };
    case types.SET_DESCRIPTIONS:
      const { descriptions: desc = {} } = action.payload;
      const descs = {
        line1: desc.line1 || [],
        line2: desc.line2 || [],
        line3: desc.line3 || [],
        line4: desc.line4 || [],
        line5: desc.line5 || [],
      };
      return {
        ...state,
        descriptions: descs,
      };
    default:
      throw new Error();
  }
};

export default reducer;
