import { appTypes as types } from './types';

interface ResetAppStateAction {
  type: typeof types.RESET_APP_STATE;
}

interface SetAppStateAction {
  type: typeof types.SET_APP_STATE;
  payload: any;
}

export type AppActionTypes = ResetAppStateAction | SetAppStateAction;

export const resetAppState = (): ResetAppStateAction => ({
  type: types.RESET_APP_STATE,
});

export const setAppState = (data: any): SetAppStateAction => ({
  type: types.SET_APP_STATE,
  payload: data,
});
