import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Col, Divider, message, Row, Select, Spin, Table, Tag } from 'antd';
import Search from 'antd/lib/input/Search';
import axios from 'axios';
import { DynamicsNavContext } from '../../dynamics-nav-utility/dynamicsNavProvider';
import {
  findDynamicsNavById,
  generateDynamicsNavTableColumns,
  getRequiredDynamicNavElements,
} from '../../dynamics-nav-utility/dynamicsNavUtil';
import { apiUri } from '../../config/vars';

const onFilter = (value: any, record: any) =>
  !value || (record.name && record.name.includes(value));

type Props = {
  ignoredIds: any;
  isOpen: boolean;
  setIgnoredIds: any;
};

const ProductSubGroupTab = ({ isOpen, ignoredIds, setIgnoredIds }: Props) => {
  const [subGroups, setSubGroups] = useState<any>(null);
  const [searchValue, setSearchValue] = useState<string | null>('');
  const [isSaving, setIsSaving] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
  const refImport = useRef(null);

  const dynamicsNav = useContext(DynamicsNavContext);

  useEffect(() => {
    const getSubGroups = async () => {
      const groupsResult = await axios.get(`${apiUri}/groups`);

      setSubGroups(groupsResult && groupsResult.data);
    };
    if (isOpen) {
      setSubGroups(null);
      getSubGroups();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setSearchValue('');
    }
  }, [isOpen]);

  const onTableRowExpand = (expanded: any, article: any) => {
    if (expanded) {
      setExpandedRowKeys([article.id]);
    } else {
      setExpandedRowKeys([]);
    }
  };

  const onChangeCheckbox = (productSubGroupCode: any, dynamicsNavId: any, checked: any) => {
    if (!checked) {
      setIgnoredIds({
        ...ignoredIds,
        [productSubGroupCode]: {
          isSaved: false,
          ids: [
            ...(ignoredIds[productSubGroupCode] ? ignoredIds[productSubGroupCode].ids : []),
            dynamicsNavId,
          ],
        },
      });
    } else {
      setIgnoredIds({
        ...ignoredIds,
        [productSubGroupCode]: {
          isSaved: false,
          ids:
            (ignoredIds[productSubGroupCode] &&
              ignoredIds[productSubGroupCode].ids.filter((id: any) => id !== dynamicsNavId)) ||
            [],
        },
      });
    }
  };

  const importSelection = (receiver: any) => {
    console.log(receiver);
    // @ts-ignore
    const select = refImport.current!.rcSelect;
    const origin = select.state.value.length && select.state.value[0]; // @ts-ignore
    console.log(select.state.value);
    if (origin) {
      setIgnoredIds({
        ...ignoredIds,
        [receiver]: {
          isSaved: false,
          ids: (ignoredIds[origin] && ignoredIds[origin].ids) || [],
        },
      });
    }
    select.state.value = [];
  };

  const saveProductSubGroupCode = async (id: any) => {
    setIsSaving(true);
    const productSubGroupCode = subGroups.find((code: any) => code.id === id);
    try {
      await axios.put(
        `${apiUri}/productsubgroupcodes/${id}`,
        JSON.stringify({
          ...productSubGroupCode,
          ignoredIds: (ignoredIds[id] && ignoredIds[id].ids) || [],
        }),
      );

      setIgnoredIds({
        ...ignoredIds,
        [id]: {
          isSaved: true,
          ids: (ignoredIds[id] && ignoredIds[id].ids) || [],
        },
      });
      message.success('Succesvol opgeslagen');
    } catch (e) {
      message.error('Er is een fout opgetreden');
    }
    setIsSaving(false);
  };

  const tableColumns = [
    {
      title: 'Naam',
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: any) => (
        <div>
          {text}{' '}
          {ignoredIds[record.id] && !ignoredIds[record.id].isSaved && (
            <Tag color="red">Niet opgeslagen</Tag>
          )}
        </div>
      ),
      filteredValue: [searchValue],
      onFilter: onFilter,
    },
    {
      title: 'Type',
      dataIndex: 'dynamicsNavId',
      key: 'dynamicsNavId',
      render: (text: string) => {
        if (!text) return <div>'-'</div>;
        const dynamicsNavElement = findDynamicsNavById(text, dynamicsNav.elements);
        return dynamicsNavElement ? `${dynamicsNavElement.desc}` : <div>'-'</div>;
      },
      // width: '150px',
    },
  ];

  return (
    <>
      {subGroups === null ? (
        <div style={{ padding: '10px', textAlign: 'center' }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row>
            <Col span={24}>
              <Search
                placeholder="Zoeken"
                onChange={e => setSearchValue(e.target.value || '')}
                style={{ width: 250, marginBottom: 10 }}
              />
            </Col>
          </Row>
          <Table
            rowKey="id"
            pagination={{ pageSize: 25 }}
            size="small"
            dataSource={subGroups}
            columns={tableColumns}
            expandedRowKeys={expandedRowKeys}
            onExpand={onTableRowExpand}
            expandedRowRender={record => (
              <>
                <Row>
                  <Col span={24}>
                    <Select
                      showSearch
                      style={{ width: 400 }}
                      placeholder="Importeer selectie"
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      ref={refImport}
                    >
                      {subGroups.map((subgroup: any) => {
                        return (
                          <Select.Option key={subgroup.id} value={subgroup.id}>
                            {subgroup.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    <Button
                      onClick={() => importSelection(record.id)}
                      style={{ marginLeft: '10px' }}
                    >
                      Importeer
                    </Button>
                    <Button
                      loading={isSaving}
                      disabled={isSaving}
                      type="primary"
                      onClick={() => saveProductSubGroupCode(record.id)}
                      style={{ marginLeft: '10px' }}
                    >
                      Opslaan
                    </Button>
                    {ignoredIds[record.id] &&
                      !ignoredIds[record.id].isSaved &&
                      ' (Niet opgeslagen)'}
                  </Col>
                </Row>
                <Divider />
                <Row>
                  {generateDynamicsNavTableColumns(dynamicsNav.elements)
                    .filter((dntc: any) =>
                      getRequiredDynamicNavElements(dynamicsNav.elements)
                        .map(el => el.id)
                        .includes(dntc.key),
                    )
                    .map((element: any, index: number) => {
                      return (
                        <Col key={element.key} span={12}>
                          <Checkbox
                            value={element.key}
                            onChange={(e: any) =>
                              onChangeCheckbox(record.id, element.key, e.target.checked)
                            }
                            checked={
                              !ignoredIds[record.id] ||
                              !ignoredIds[record.id].ids.includes(element.key)
                            }
                          >
                            {element.title}
                          </Checkbox>
                        </Col>
                      );
                    })}
                </Row>
              </>
            )}
          />
        </>
      )}
    </>
  );
};

export default ProductSubGroupTab;
