import React, { useState, useEffect } from 'react';
import { FormattedMessage, InjectedIntl, intlShape } from 'react-intl';
import { Card, Icon, Input } from 'antd';

import CodeList from '../CodeList/CodeList';

import messages from '../messages';

type Props = {
  name?: string;
  onDelete: () => void;
  onUpdate: (e: any) => void;
  isDisabled: boolean;
  intl: InjectedIntl;
  codes: any[];
  index: number;
};

const Category = ({
  name,
  onDelete,
  onUpdate,
  isDisabled,
  intl,
  codes,
  index,
}: Props) => {
  return (
    <Card
      size="small"
      title={
        <Input
          disabled={isDisabled}
          placeholder={intl.formatMessage(messages.categoryName)}
          defaultValue={name}
          onChange={(e) => onUpdate({ name: e.target.value })}
        />
      }
      extra={
        !isDisabled && (
          <a style={{ marginLeft: '8px' }} onClick={onDelete}>
            <Icon type="delete" />
          </a>
        )
      }
    >
      <CodeList
        categoryIndex={index}
        codes={codes}
        intl={intl}
        isDisabled={isDisabled}
      />
    </Card>
  );
};

export default Category;
