import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Card, Typography, Tag, Badge } from 'antd';
import { FormattedMessage } from 'react-intl';

import messages from '../../messages';

const { Text, Paragraph } = Typography;

const getListStyle = () => ({
  display: 'flex',
  minHeight: '39px',
  padding: 8,
  flex: '1',
  overflowX: 'auto' as 'auto',
});

const prefixShortMapper = {
  C_75028: 'CT:',
  C_75029: 'M:',
  C_75030: 'LT:',
  C_75031: 'C:',
  C_75032: 'H:',
  C_75033: 'K:',
  C_75034: 'A:',
  C_75035: 'HS:',
  C_75037: 'RS:',
  C_75038: 'ET:',
  C_75039: 'SST:',
  C_75042: 'L:',
  C_75044: 'T:',
  C_75045: 'C:',
  C_75046: 'C+P:',
  C_75047: 'B+T:',
  C_75049: 'BR:',
};

export const enrichShortName = (prop: any) => {
  if (prop.dynamicsNavId && (prefixShortMapper as any)[prop.dynamicsNavId]) {
    return `${(prefixShortMapper as any)[prop.dynamicsNavId]}${prop.abbreviation}`;
  }
  return prop.abbreviation;
};

export default function ShortDescriptions({
  isDisabled,
  longDescriptions,
}: {
  isDisabled: boolean;
  longDescriptions: any;
}) {
  function onDragEnd(result: any) {}

  return (
    <div style={{ marginBottom: '1em' }}>
      <Text disabled={isDisabled}>
        <FormattedMessage {...messages.shortDescriptions} />
      </Text>
      <DragDropContext onDragEnd={onDragEnd}>
        <Card size="small" style={{ marginTop: 8 }} className="droppable-inside">
          <div style={{ display: 'flex' }}>
            <Droppable droppableId="list" direction="horizontal">
              {provided => (
                <div style={getListStyle()} ref={provided.innerRef} {...provided.droppableProps}>
                  {[]
                    .concat(...(Object.values(longDescriptions) as any))
                    .map((item: any, index: number) => (
                      <Draggable
                        key={`${item.name}_${item.dynamicsNavId}`}
                        draggableId={`${item.name}_${item.dynamicsNavId}`}
                        index={index}
                        isDragDisabled={true}
                      >
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Tag className="ant-btn disabled">{enrichShortName(item)}</Tag>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Badge
              count={
                []
                  .concat(...(Object.values(longDescriptions) as any))
                  .map((description: any) => enrichShortName(description))
                  .join('-').length
              }
              overflowCount={999}
              style={{
                backgroundColor: '#fff',
                color: '#999',
                boxShadow: '0 0 0 1px #d9d9d9 inset',
                margin: 8,
              }}
            />
          </div>
        </Card>
      </DragDropContext>
    </div>
  );
}
