import { categoryTypes as types } from './types';

interface IAddCategoryAction {
  type: typeof types.ADD_CATEGORY;
  payload: any;
}

export interface IUpdateCategoryAction {
  type: typeof types.UPDATE_CATEGORY;
  payload: {
    categoryIndex: number;
    values: any;
  };
}

interface IDeleteCategoryAction {
  type: typeof types.DELETE_CATEGORY;
  payload: {
    categoryIndex: number;
  };
}

export type TCategoryActionTypes =
  | IAddCategoryAction
  | IUpdateCategoryAction
  | IDeleteCategoryAction;

export const addCategory = (position = null): IAddCategoryAction => ({
  type: types.ADD_CATEGORY,
  payload: {
    position,
  },
});

export const updateCategory = ({ categoryIndex, values }: any): IUpdateCategoryAction => ({
  type: types.UPDATE_CATEGORY,
  payload: {
    categoryIndex,
    values,
  },
});

export const deleteCategory = (categoryIndex: number): IDeleteCategoryAction => ({
  type: types.DELETE_CATEGORY,
  payload: {
    categoryIndex,
  },
});
