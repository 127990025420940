import { tagTypes as types } from '../actions/types';

export interface AppState {
  name: string;
}

const reducer = (state: AppState, action: any) => {
  switch (action.type) {
    case types.SET_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    default:
      throw new Error();
  }
};

export default reducer;
