import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, Checkbox, Spin } from 'antd';

const { Text } = Typography;

const Filters = () => {
  const [checkedFilters, setCheckedFilters] = useState({});
  const [possibleFilters, setPossibleFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  useEffect(() => {
    const getFilters = async () => {
      try {
        const sanitized = Object.keys(checkedFilters).reduce((san, currentFilterKey) => {
          if ((checkedFilters as any)[currentFilterKey].length > 0) {
            return {
              ...san,
              [currentFilterKey]: (checkedFilters as any)[currentFilterKey],
            };
          }

          return san;
        }, {});
        const result = await axios.post('http://198.211.126.99', sanitized);
        // const result = await axios.post('http://localhost:80', sanitized);
        setPossibleFilters(result.data.results);
        setCount(result.data.count);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    };

    setLoading(true);

    getFilters();
  }, [checkedFilters]);

  console.log(possibleFilters);
  return (
    <>
      {loading ? (
        <div style={{ width: '200px', margin: '0 auto', padding: '20px' }}>
          <Spin />
        </div>
      ) : (
        <div style={{ padding: '20px' }}>
          <Text code>Totaal</Text>: {count}
          {Object.keys(possibleFilters).map(filterKey => {
            return (
              <div>
                <br />
                <Text code>{filterKey}</Text>
                <br />
                {(possibleFilters as any)[filterKey].map((filterValue: any) => {
                  return (
                    <>
                      <Checkbox
                        onChange={e => {
                          if (!e.target.checked) {
                            setCheckedFilters({
                              ...checkedFilters,
                              [filterKey]: (checkedFilters as any)[filterKey].filter(
                                (v: any) => v !== filterValue,
                              ),
                            });
                          } else {
                            setCheckedFilters({
                              ...checkedFilters,
                              [filterKey]: [
                                ...((checkedFilters as any)[filterKey] || []),
                                filterValue,
                              ],
                            });
                          }
                        }}
                        checked={
                          (checkedFilters as any)[filterKey] &&
                          (checkedFilters as any)[filterKey].includes(filterValue)
                        }
                      >
                        {filterValue}
                      </Checkbox>
                      <br />
                    </>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Filters;
