import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Icon,
  Menu,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Spin,
  Switch,
  Table,
  Tag,
  Typography,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';

import csv from 'csvtojson';

import Search from 'antd/lib/input/Search';
import { apiUri } from '../../config/vars';
import generalMessages from '../../messages';
import TagList from '../CategoryBlock/TagList';
import DuplicateModal from './DuplicateModal';
import ESSyncModal from './ESSyncModal';
import ExportsModal from './ExportsModal';
import messages from './messages';
import SettingsModal from './SettingsModal';
import SubMenu from 'antd/lib/menu/SubMenu';

const { Text } = Typography;

interface Props extends InjectedIntlProps {
  isDisabled: boolean;
  onReset: ((e?: React.MouseEvent<any, MouseEvent> | undefined) => void) | undefined;
  onNewEntry: ((e?: React.MouseEvent<any, MouseEvent> | undefined) => void) | undefined;
  onPreviousEntry: (data: any) => void;
}

const createDateFormat = (dateString: string) => {
  const date = new Date(Math.floor(parseInt(dateString, 10)));
  return `${date.getDate()}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()} ${(
    '0' + date.getHours()
  ).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
};

const onFilter = (value: any, record: any) =>
  !value ||
  (record.display && record.display.includes(value)) ||
  (record.data.tags && record.data.tags.find((t: string) => t.includes(value)));

const StartBlock = ({ isDisabled, onReset, onNewEntry, intl, onPreviousEntry }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showLosseOnderdelen, setShowLosseOnderdelen] = useState(false);
  const [isExportsModalOpen, setIsExportsModalOpen] = useState(false);
  const [isESSyncModalOpen, setIsESSyncModalOpen] = useState(false);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const [isDuplicateOpen, setDuplicateOpen] = useState(false);
  const [entries, setEntries] = useState<any>(null);
  const [filteredEntries, setFilteredEntries] = useState<any>(null);
  const [ignoredIds, setIgnoredIds] = useState<any>({});
  const [duplicates, setDuplicates] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [searchValue, setSearchValue] = useState<string | null>('');
  const [isUpdatingInExport, setIsUpdatingInExport] = useState(false);

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  };

  useEffect(() => {
    const getDuplicates = async () => {
      try {
        const result = await axios.get(`${apiUri}/duplicates`);

        setDuplicates(result.data);
      } catch (e) {
        console.log('ERROR getting duplicates');
      }
    };

    if (isDuplicateOpen || duplicates === null) {
      getDuplicates();
    }
  }, [isDuplicateOpen]);

  useEffect(() => {
    if (entries) {
      if (showLosseOnderdelen) {
        setFilteredEntries(entries.filter((entry: any) => entry.data.type === 'loose'));
      } else {
        setFilteredEntries(entries.filter((entry: any) => entry.data.type !== 'loose'));
      }
    }
  }, [entries, showLosseOnderdelen]);

  useEffect(() => {
    const getEntries = async () => {
      const entriesResult = await axios.get(`${apiUri}/entries`);

      const entries = entriesResult.data
        .sort((a: any, b: any) => b.createdAt - a.createdAt)
        .map((entry: any) => ({
          ...entry,
          display:
            entry.data.name ||
            (entry.data.categories[0] &&
              entry.data.categories[0].codes &&
              entry.data.categories[0].codes[0] &&
              entry.data.categories[0].codes[0].properties &&
              entry.data.categories[0].codes[0].properties.find(
                (property: any) => property.name && property.abbreviation,
              ) &&
              `${
                entry.data.categories[0].codes[0].properties.find(
                  (property: any) => property.name && property.abbreviation,
                ).name
              }...`),
          dateNewPrice: entry.data.dateNewPrice,
          priceCoeff: entry.data.priceCoeff,
          prevdateNewPrice: entry.data.prevdateNewPrice,
          prevpriceCoeff: entry.data.prevpriceCoeff,
          workstation: entry.data.workstation,
          createdAt: createDateFormat(entry.createdAt),
        }));

      setEntries(entries);
    };

    const getIgnoredIds = async () => {
      const result = await axios.get(`${apiUri}/productsubgroupcodes`);

      const ignoredIdsResult = result.data.reduce((ignoredIdWrapper: any, currentCode: any) => {
        return {
          ...ignoredIdWrapper,
          [currentCode.id]: {
            isSaved: true,
            ids: currentCode.ignoredIds,
          },
        };
      }, {});

      setIgnoredIds(ignoredIdsResult);
    };

    if (isOpen || isDuplicateOpen || isSettingsOpen) {
      setIgnoredIds({});
      setEntries(null);
      setSearchValue('');
      getEntries();
      getIgnoredIds();
    }
  }, [isOpen, isDuplicateOpen, isSettingsOpen]);

  const onDelete = async (id: string) => {
    setEntries(entries.filter((entry: any) => entry.id !== id));
    await axios.delete(`${apiUri}/entries/${id}`);
  };

  const inExport = async (inExport: boolean, ids: any) => {
    try {
      setIsUpdatingInExport(true);
      await axios.put(`${apiUri}/export/entries`, {
        inExport,
        ids,
      });
      message.success('De configuraties werden gewijzigd.');
      setEntries(
        entries.map((entry: any) => {
          if (!ids.includes(entry.id)) {
            return entry;
          }
          console.log('match', entry);

          return {
            ...entry,
            data: {
              ...entry.data,
              inExport,
            },
          };
        }),
      );
      setIsUpdatingInExport(false);
    } catch (e) {
      setIsUpdatingInExport(false);
      message.error('Fout opgetreden.');
    }
  };

  const inExport2 = async (inExport2: boolean, ids: any) => {
    try {
      setIsUpdatingInExport(true);
      await axios.put(`${apiUri}/export/entries`, {
        inExport2,
        ids,
      });
      message.success('De configuratie werd gewijzigd.');
      setEntries(
        entries.map((entry: any) => {
          if (!ids.includes(entry.id)) {
            return entry;
          }
          console.log('match', entry);

          return {
            ...entry,
            data: {
              ...entry.data,
              inExport2,
            },
          };
        }),
      );
      setIsUpdatingInExport(false);
    } catch (e) {
      setIsUpdatingInExport(false);
      message.error('Fout opgetreden.');
    }
  };

  const tableColumns = [
    {
      title: 'Exp1',
      render: (record: any) => {
        return (
          <Switch
            key={`${record.id}_${record.data.inExport}`}
            size="small"
            defaultChecked={record.data.inExport}
            onChange={change => inExport(change, [record.id])}
          />
        );
      },
      sorter: (a: any, b: any) => {
        return +a.data.inExport || 0 - +b.data.inExport || 0;
      },
    },
    {
      title: 'Exp2',
      render: (record: any) => {
        return (
          <Switch
            key={`${record.id}_${record.data.inExport2}`}
            size="small"
            defaultChecked={record.data.inExport2}
            onChange={change => inExport2(change, [record.id])}
          />
        );
      },
      sorter: (a: any, b: any) => {
        return +a.data.inExport2 || 0 - +b.data.inExport2 || 0;
      },
    },
    {
      title: 'Naam',
      dataIndex: 'display',
      key: 'display',
      width: '800px',
      render: (text: any) => <div style={{ width: '800px', wordWrap: 'break-word' }}>{text}</div>,
      filteredValue: [searchValue],
      onFilter,
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      width: '150px',
      render: (text: any, record: any) => {
        const {
          data: { tags },
        } = record;
        return <TagList tags={tags || []} />;
      },
      // filteredValue: [searchValue],
      // onFilter,
    },
    {
      title: 'Status',
      render: (record: any) => {
        const { statusCount = {} } = record.data;
        return (
          <Tag
            color={
              statusCount.count === statusCount.total && !statusCount.obsolete ? 'green' : 'red'
            }
          >{`${statusCount.count}${statusCount.obsolete ? ` (+${statusCount.obsolete})` : ''}/${
            statusCount.total
          }`}</Tag>
        );
      },
      width: '70px',
    },
    {
      title: 'Laatste update',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '170px',
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => {
        return moment(a.createdAt).unix() - moment(b.createdAt).unix();
      },
    },
    {
      title: '',
      key: 'action',
      width: '320px',
      render: (text: any, { id, data }: any) => {
        return (
          <span>
            <Popconfirm
              title="Ben je zeker dat je deze configuratie wilt aanpassen?"
              icon={null}
              okText={<FormattedMessage {...generalMessages.yes} />}
              cancelText={<FormattedMessage {...generalMessages.cancel} />}
              onConfirm={async () => {
                setIsLoading(true);
                const {
                  data: { data },
                } = await axios.get(`${apiUri}/entries/${id}`);
                setIsLoading(false);
                setOpen(false);
                onPreviousEntry({ id, data });
              }}
              placement="bottomRight"
            >
              <a>Aanpassen</a>
            </Popconfirm>

            <Divider type="vertical" />
            <Popconfirm
              title="Ben je zeker dat je deze configuratie wilt dupliceren?"
              icon={null}
              okText={<FormattedMessage {...generalMessages.yes} />}
              cancelText={<FormattedMessage {...generalMessages.cancel} />}
              onConfirm={async () => {
                setIsLoading(true);
                const {
                  data: { data },
                } = await axios.get(`${apiUri}/entries/${id}`);
                setIsLoading(false);
                setOpen(false);
                onPreviousEntry({ data });
              }}
              placement="bottomRight"
            >
              <a>Dupliceren</a>
            </Popconfirm>

            <Divider type="vertical" />
            <Popconfirm
              title="Ben je zeker dat je deze configuratie wilt verwijderen?"
              icon={null}
              okText={<FormattedMessage {...generalMessages.yes} />}
              cancelText={<FormattedMessage {...generalMessages.cancel} />}
              onConfirm={() => {
                onDelete(id);
              }}
              placement="bottomRight"
            >
              <a>Verwijderen</a>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const inputFileEl = React.useRef(null);

  const readAsText = (file: any): Promise<string> => {
    return new Promise(resolve => {
      var reader = new FileReader();
      reader.onload = function(event: any) {
        resolve(event.target.result);
      };
      reader.readAsText(file);
    });
  };

  const csvToJson = async (str: string) => {
    return new Promise(resolve => {
      csv({
        delimiter: ';',
      })
        .fromString(str)
        .then((jsonObj: any) => {
          resolve(jsonObj);
        });
    });
  };

  const bulkUpdateFile = async (e: any) => {
    console.log('bulkUpdateFile');
    var files = e.currentTarget.files;
    if (files.length === 0) {
      console.log('No file is selected');
      return;
    }

    const result = await readAsText(files[0]);

    const json = await csvToJson(result);
    console.log(json);

    try {
      await axios.put(`${apiUri}/entries/bulk`, json);

      message.success('De wijzigingen werden doorgevoerd.');
    } catch (e) {
      message.error('Er is een fout opgetreden.');
    }
  };

  const onBulkUpdateClick = () => {
    // @ts-ignore
    inputFileEl.current.click();
  };

  return (
    <Row>
      <Col>
        <Card
          size="small"
          title={
            <Text disabled={isDisabled}>
              <FormattedMessage {...generalMessages.welcome} />{' '}
              {isDisabled && (
                <Icon theme="filled" type="check-circle" style={{ color: '#52c41a' }} />
              )}
            </Text>
          }
          extra={
            isDisabled && (
              <Popconfirm
                title={<FormattedMessage {...generalMessages.areYouSureYouWantToRestart} />}
                icon={null}
                okText={<FormattedMessage {...generalMessages.yes} />}
                cancelText={<FormattedMessage {...generalMessages.cancel} />}
                onConfirm={onReset}
                placement="bottomRight"
              >
                <a href="#">
                  <FormattedMessage {...generalMessages.restart} />
                </a>
              </Popconfirm>
            )
          }
        >
          {duplicates && duplicates.data && duplicates.data.length > 0 && (
            <Row>
              <Col>
                <Alert
                  message={
                    <span>
                      Er werden duplicate combinaties gedetecteerd (
                      {createDateFormat(duplicates.createdAt)}).{' '}
                      <a onClick={() => setDuplicateOpen(true)}>Toon details</a>
                    </span>
                  }
                  type="warning"
                  showIcon
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col span={18}>
              <Button
                style={{ margin: '0 10px 0 0' }}
                disabled={isDisabled}
                type="primary"
                onClick={() => setOpen(true)}
              >
                <FormattedMessage {...generalMessages.previousEntry} />
              </Button>
              <Button
                style={{ margin: '0 10px 0 0' }}
                disabled={isDisabled}
                type="primary"
                onClick={onNewEntry}
              >
                <FormattedMessage {...generalMessages.start} />
              </Button>
              <Button style={{ margin: '0 10px 0 0' }} onClick={() => setIsExportsModalOpen(true)}>
                Exports beheren
              </Button>
              <Button style={{ margin: '0 10px 0 0' }} onClick={() => setIsESSyncModalOpen(true)}>
                ES Synchronisatie
              </Button>
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              <Button icon="setting" disabled={isDisabled} onClick={() => setSettingsOpen(true)}>
                Instellingen
              </Button>
            </Col>
          </Row>
          <Modal
            title={intl.formatMessage(messages.modalEntriesTitle)}
            visible={isOpen}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            width="1700px"
            footer={null}
          >
            {entries === null ? (
              <div style={{ padding: '10px', textAlign: 'center' }}>
                <Spin />
              </div>
            ) : (
              <>
                <Row>
                  <Col span={6}>
                    <div>
                      <Radio.Group
                        onChange={event => setShowLosseOnderdelen(event.target.value === 'loose')}
                        defaultValue="configs"
                      >
                        <Radio.Button value="configs">Configuraties</Radio.Button>
                        <Radio.Button value="loose">Losse onderdelen</Radio.Button>
                      </Radio.Group>
                      <Dropdown
                        overlay={
                          <Menu onClick={() => undefined}>
                            <SubMenu
                              title={`Filter (Aantal: ${
                                entries.filter(
                                  (entry: any) =>
                                    onFilter(searchValue, entry) &&
                                    ((showLosseOnderdelen && entry.data.type === 'loose') ||
                                      (!showLosseOnderdelen && entry.data.type !== 'loose')),
                                ).length
                              })`}
                            >
                              <Menu.Item
                                onClick={() => {
                                  inExport(
                                    true,
                                    entries
                                      .filter(
                                        (entry: any) =>
                                          onFilter(searchValue, entry) &&
                                          ((showLosseOnderdelen && entry.data.type === 'loose') ||
                                            (!showLosseOnderdelen && entry.data.type !== 'loose')),
                                      )
                                      .map((entry: any) => entry.id),
                                  );
                                }}
                              >
                                Activeer Exp1
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  inExport(
                                    false,
                                    entries
                                      .filter(
                                        (entry: any) =>
                                          onFilter(searchValue, entry) &&
                                          ((showLosseOnderdelen && entry.data.type === 'loose') ||
                                            (!showLosseOnderdelen && entry.data.type !== 'loose')),
                                      )
                                      .map((entry: any) => entry.id),
                                  );
                                }}
                              >
                                Deactiveer Exp1
                              </Menu.Item>
                              <Menu.Divider />
                              <Menu.Item
                                onClick={() => {
                                  inExport2(
                                    true,
                                    entries
                                      .filter(
                                        (entry: any) =>
                                          onFilter(searchValue, entry) &&
                                          ((showLosseOnderdelen && entry.data.type === 'loose') ||
                                            (!showLosseOnderdelen && entry.data.type !== 'loose')),
                                      )
                                      .map((entry: any) => entry.id),
                                  );
                                }}
                              >
                                Activeer Exp2
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  inExport2(
                                    false,
                                    entries
                                      .filter(
                                        (entry: any) =>
                                          onFilter(searchValue, entry) &&
                                          ((showLosseOnderdelen && entry.data.type === 'loose') ||
                                            (!showLosseOnderdelen && entry.data.type !== 'loose')),
                                      )
                                      .map((entry: any) => entry.id),
                                  );
                                }}
                              >
                                Deactiveer Exp2
                              </Menu.Item>
                            </SubMenu>
                            <SubMenu title={`Selectie (Aantal: ${selectedRowKeys.length})`}>
                              <Menu.Item
                                onClick={() => {
                                  inExport(true, selectedRowKeys);
                                }}
                              >
                                Activeer Exp1
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  inExport(false, selectedRowKeys);
                                }}
                              >
                                Deactiveer Exp1
                              </Menu.Item>
                              <Menu.Divider />
                              <Menu.Item
                                onClick={() => {
                                  inExport2(true, selectedRowKeys);
                                }}
                              >
                                Activeer Exp2
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  inExport2(false, selectedRowKeys);
                                }}
                              >
                                Deactiveer Exp2
                              </Menu.Item>
                              <Menu.Divider />
                              <Menu.Item onClick={() => setSelectedRowKeys([])}>
                                Reset selectie
                              </Menu.Item>
                            </SubMenu>
                          </Menu>
                        }
                      >
                        <Button
                          style={{ marginLeft: '10px' }}
                          loading={isUpdatingInExport}
                          disabled={isUpdatingInExport}
                        >
                          Acties <Icon type="down" />
                        </Button>
                      </Dropdown>
                    </div>
                  </Col>
                  <Col span={6}>
                    <Search
                      placeholder="Zoeken"
                      onChange={e => setSearchValue(e.target.value || '')}
                      style={{ width: 250, marginBottom: 10 }}
                    />
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <CSVLink
                      separator={';'}
                      enclosingCharacter={``}
                      className="ant-btn ant-btn-primary"
                      data={(entries || [])
                        .filter((entry: any) => onFilter(searchValue, entry))
                        .map((entry: any) => ({
                          id: entry.id,
                          name: entry.display,
                          prevpriceCoeff: entry.prevpriceCoeff,
                          prevdateNewPrice: entry.prevdateNewPrice,
                          dateNewPrice: entry.dateNewPrice,
                          priceCoeff: entry.priceCoeff,
                          workstation: entry.workstation,
                          updatedAt: entry.createdAt,
                        }))}
                      filename={`export_${searchValue || 'all'}.csv`}
                    >
                      Exporteer lijst
                    </CSVLink>
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      onChange={bulkUpdateFile}
                      ref={inputFileEl}
                    />
                    <Button
                      style={{ margin: '0 0 0 10px' }}
                      type="primary"
                      onClick={onBulkUpdateClick}
                    >
                      Bulk update
                    </Button>
                  </Col>
                </Row>
                <Table
                  rowSelection={{
                    selectedRowKeys,
                    onChange: (selectedRowKeys: any) => setSelectedRowKeys(selectedRowKeys),
                  }}
                  rowKey="id"
                  pagination={{ pageSize: 25 }}
                  size="small"
                  dataSource={filteredEntries}
                  // @ts-ignore
                  columns={tableColumns}
                  loading={isLoading}
                  // onRow={(record, rowIndex) => {
                  //   return {
                  //     onClick: () => {
                  //       setOpen(false);
                  //       onPreviousEntry(entries![rowIndex].data);
                  //     },
                  //   };
                  // }}
                />
              </>
            )}
          </Modal>
          <DuplicateModal
            isOpen={isDuplicateOpen}
            setOpen={setDuplicateOpen}
            entries={entries}
            duplicates={(duplicates && duplicates.data) || null}
            onModify={async (entry: any) => {
              const { id } = entry;
              setIsLoading(true);
              const {
                data: { data },
              } = await axios.get(`${apiUri}/entries/${id}`);
              setDuplicateOpen(false);
              onPreviousEntry({ id, data });
              setIsLoading(false);
            }}
            onDelete={(id: string) => {
              onDelete(id);
            }}
            isLoading={isLoading}
          />
          {/* <ProductSubGroupModal
            isOpen={isSettingsOpen}
            setOpen={setSettingsOpen}
            ignoredIds={ignoredIds}
            setIgnoredIds={setIgnoredIds}
          /> */}
          <SettingsModal
            isOpen={isSettingsOpen}
            setOpen={setSettingsOpen}
            ignoredIds={ignoredIds}
            setIgnoredIds={setIgnoredIds}
          />
          <ExportsModal isOpen={isExportsModalOpen} onClose={() => setIsExportsModalOpen(false)} />
          <ESSyncModal isOpen={isESSyncModalOpen} onClose={() => setIsESSyncModalOpen(false)} />
        </Card>
      </Col>
    </Row>
  );
};

export default injectIntl(StartBlock);
