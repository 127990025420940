import { Badge, Button, Col, Modal, Radio, Row, Switch, Table, Tag } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { useState } from 'react';

import axios from 'axios';
import { apiUri } from '../../config/vars';

const baseUrl =
  process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENVIRONMENT === 'development'
    ? `https://zgcpnzurkc.execute-api.eu-west-1.amazonaws.com/dev`
    : `https://61ddzdnbo3.execute-api.eu-west-1.amazonaws.com/prod`;

const onFilter = (value: any, record: any) =>
  !value ||
  (record.display && record.display.includes(value)) ||
  (record.data.tags && record.data.tags.find((t: string) => t.includes(value)));

function ESSyncModal({ isOpen, onClose }: any) {
  const [searchValue, setSearchValue] = useState<string | null>('');
  const [showSyncConfigsOnly, setShowSyncConfigsOnly] = useState(true);
  const [entries, setEntries] = useState<{ inessync: boolean }[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refetch, setShouldRefetch] = useState(Date.now());
  const [showLosseOnderdelen, setShowLosseOnderdelen] = useState(false);
  const [filteredEntries, setFilteredEntries] = useState<any>([]);
  const [isBulkSyncing, setIsBulkSyncing] = useState(false);
  const [isBulkAddToSync, setIsBulkAddToSync] = useState(false);

  const getEntries = async () => {
    const entriesResult = await axios.get(`${apiUri}/entries`);
    const essyncResult = await axios.get(`${baseUrl}/essync`);

    const entries = entriesResult.data
      .sort((a: any, b: any) => b.createdAt - a.createdAt)
      .map((entry: any) => {
        const essync = essyncResult.data.find((ess: any) => ess.id === entry.id);
        return {
          ...entry,
          display:
            entry.data.name ||
            (entry.data.categories[0] &&
              entry.data.categories[0].codes &&
              entry.data.categories[0].codes[0] &&
              entry.data.categories[0].codes[0].properties &&
              entry.data.categories[0].codes[0].properties.find(
                (property: any) => property.name && property.abbreviation,
              ) &&
              `${
                entry.data.categories[0].codes[0].properties.find(
                  (property: any) => property.name && property.abbreviation,
                ).name
              }...`),
          inessync: !!essync,
          requests:
            essync &&
            essync.requests.sort(
              (a: { created_at: string }, b: { created_at: string }) =>
                new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
            ),
          in_sync: essync && essync.in_sync,
        };
      });

    setEntries(entries);
    setIsLoading(false);
  };

  React.useEffect(() => {
    getEntries();
  }, [isOpen, refetch]);

  React.useEffect(() => {
    if (entries) {
      if (showLosseOnderdelen) {
        setFilteredEntries(entries.filter((entry: any) => entry.data.type === 'loose'));
      } else {
        setFilteredEntries(entries.filter((entry: any) => entry.data.type !== 'loose'));
      }
    }
  }, [entries, showLosseOnderdelen]);

  const onChangeToggle = (record: any) => async (change: any) => {
    console.log(record.id, change);
    if (change) {
      await axios.post(`${baseUrl}/essync`, {
        id: record.id,
      });
    } else {
      await axios.delete(`${baseUrl}/essync/${record.id}`);
    }

    setShouldRefetch(Date.now());
  };

  const onSync = (record: any) => async () => {
    await axios.post(`${baseUrl}/essync/${record.id}/request`);

    setShouldRefetch(Date.now());
  };

  const tableColumns = [
    {
      title: 'ES Sync',
      render: (record: any) => {
        return (
          <Switch
            key={`${record.id}_essync`}
            size="small"
            defaultChecked={record.inessync}
            onChange={onChangeToggle(record)}
          />
        );
      },
      sorter: (a: any, b: any) => {
        return +a.inessync || 0 - +b.inessync || 0;
      },
    },
    {
      title: 'Naam',
      dataIndex: 'display',
      key: 'display',
      // width: '800px',
      render: (text: any) => <div style={{ width: '800px', wordWrap: 'break-word' }}>{text}</div>,
      filteredValue: [searchValue],
      onFilter,
    },
    {
      title: 'Status',
      render: (record: any) => {
        if (!record.inessync) {
          return null;
        }
        return (
          <Tag color={record.in_sync ? 'green' : 'red'}>
            {record.in_sync ? 'in sync' : 'niet in sync'}
          </Tag>
        );
      },
      width: '70px',
    },
    {
      title: 'Actie',
      render: (record: any) => {
        return (
          <Button disabled={!record.inessync || record.in_sync} onClick={onSync(record)}>
            Synchroniseer
          </Button>
        );
      },
      width: '70px',
    },
  ];

  return (
    <Modal
      title={'ES Synchronisatie'}
      visible={isOpen}
      onCancel={onClose}
      width="1500px"
      footer={
        <div style={{ textAlign: 'left' }}>
          <Button
            onClick={async () => {
              try {
                setIsBulkAddToSync(true);
                const configIds = filteredEntries
                  .filter((entry: any) => onFilter(searchValue, entry) && !entry.inessync)
                  .map((entry: any) => entry.id);

                for (const id of configIds) {
                  await axios.post(`${baseUrl}/essync`, {
                    id,
                  });
                }

                setShouldRefetch(Date.now());
                setIsBulkAddToSync(false);
              } catch (e) {
                setShouldRefetch(Date.now());
                setIsBulkAddToSync(false);
              }
            }}
            type="primary"
            loading={isBulkAddToSync}
            disabled={isBulkAddToSync}
          >
            Voeg configuraties toe aan synchronisatie
          </Button>
          <Button
            onClick={async () => {
              try {
                setIsBulkSyncing(true);
                const configIds = filteredEntries
                  .filter(
                    (entry: any) =>
                      onFilter(searchValue, entry) && entry.inessync && !entry.in_sync,
                  )
                  .map((entry: any) => entry.id);

                for (const id of configIds) {
                  await axios.post(`${baseUrl}/essync/${id}/request`);
                }

                setShouldRefetch(Date.now());
                setIsBulkSyncing(false);
              } catch (e) {
                setShouldRefetch(Date.now());
                setIsBulkSyncing(false);
              }
            }}
            type="dashed"
            loading={isBulkSyncing}
            disabled={isBulkSyncing}
          >
            Synchroniseer configuraties
          </Button>
        </div>
      }
    >
      <>
        <Row>
          <Col span={5}>
            <div>
              <Radio.Group
                onChange={event => setShowLosseOnderdelen(event.target.value === 'loose')}
                defaultValue="configs"
              >
                <Radio.Button value="configs">Configuraties</Radio.Button>
                <Radio.Button value="loose">Losse onderdelen</Radio.Button>
              </Radio.Group>
            </div>
          </Col>
          <Col span={7}>
            <Search
              placeholder="Zoeken"
              onChange={e => setSearchValue(e.target.value || '')}
              style={{ width: 250, marginBottom: 10 }}
            />
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button
              style={{ margin: '0 0 0 10px' }}
              type="primary"
              onClick={() => {
                setShowSyncConfigsOnly(showSyncConfigsOnly => {
                  return !showSyncConfigsOnly;
                });
              }}
            >
              {showSyncConfigsOnly
                ? 'Toon alle configuraties'
                : 'Toon enkel configuraties in ES sync'}
            </Button>
          </Col>
        </Row>
        <Table
          rowKey="id"
          pagination={{ pageSize: 25 }}
          size="small"
          expandedRowRender={record => expandedRowRender(record)}
          dataSource={filteredEntries.filter((entry: any) => {
            if (!showSyncConfigsOnly) {
              return true;
            }

            return entry.inessync;
          })}
          // @ts-ignore
          columns={tableColumns}
          loading={isLoading}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: () => {
          //       setOpen(false);
          //       onPreviousEntry(entries![rowIndex].data);
          //     },
          //   };
          // }}
        />
      </>
    </Modal>
  );
}

export default ESSyncModal;

const expandedRowRender = (record: any) => {
  console.log(record);
  const columns = [
    { title: 'Id', dataIndex: 'id', key: 'id' },
    {
      title: 'Status',
      key: 'status',
      render: (req: { status: string }) => {
        if (req.status === 'done') {
          return (
            <span>
              <Badge status="success" />
              Klaar
            </span>
          );
        }
        return (
          <span>
            <Badge status="processing" />
            In behandeling
          </span>
        );
      },
    },
    {
      title: 'Verstuurd naar ES',
      dataIndex: 'sent_at',
      key: 'sent_at',
      render: (r: string) => <span className="table-operation">{(r && dateFormat(r)) || '-'}</span>,
    },
    {
      title: 'Aangemaakt',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (r: string) => <span className="table-operation">{dateFormat(r)}</span>,
    },
    {
      title: 'Download',
      dataIndex: 'file_url',
      key: 'file_url',
      render: (r: any) => (
        <span className="table-operation">
          <a href={r} target="_blank">
            Download
          </a>
        </span>
      ),
    },
  ];

  if (!record.requests || record.requests.length === 0) {
    return null;
  }
  return <Table columns={columns} dataSource={record.requests} pagination={false} />;
};

export const dateFormat = (dateString: string) => {
  console.log(dateString);
  const date = new Date(dateString);
  console.log(date);
  return `${date.getDate()}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()} ${(
    '0' + date.getHours()
  ).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
};
