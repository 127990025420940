import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import axios from 'axios';
import { DynamicsNavElement } from './dynamicsNavUtil';
import { apiUri } from '../config/vars';

export interface DynamicsNavContextInterface {
  elements: DynamicsNavElement[];
  updateElements?: () => void;
}

export const DynamicsNavContext = React.createContext<DynamicsNavContextInterface>({
  elements: [],
});

/**
 * Asynchronous function to fetch the dynamicsNav table from the DynamoDB
 */
export const fetchDynamicsNavElements = async () => {
  try {
    const dynamicsNav = await axios.get(`${apiUri}/dynamicsnavids`);
    return dynamicsNav.data as DynamicsNavElement[];
  } catch (e) {
    message.error('Er is een fout opgetreden');
    return [];
  }
};

export default function DynamicsNavProvider({ children }: any) {
  const [dynamicsNavIds, setDynamicsNavids] = useState<DynamicsNavElement[]>([]);

  async function fetchData() {
    const dynamicsNavElements = await fetchDynamicsNavElements();
    setDynamicsNavids(dynamicsNavElements);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const updateElements = () => {
    return fetchData();
  };

  return (
    <DynamicsNavContext.Provider
      value={{
        elements: dynamicsNavIds,
        updateElements,
      }}
    >
      {children}
    </DynamicsNavContext.Provider>
  );
}
