import { descriptionTypes as types } from './types';

interface GenerateDescriptionsAction {
  type: typeof types.GENERATE_DESCRIPTIONS
  payload: {
    categories: any,
  }
}

interface SetDescriptionsAction {
  type: typeof types.SET_DESCRIPTIONS
  payload: {
    descriptions: any,
  }
}

export type DescriptionActionTypes = GenerateDescriptionsAction | SetDescriptionsAction;

export const generateDescriptions = (categories: any): GenerateDescriptionsAction => ({
  type: types.GENERATE_DESCRIPTIONS,
  payload: {
    categories,
  },
});

export const setDescriptions = (descriptions: any): SetDescriptionsAction => ({
  type: types.SET_DESCRIPTIONS,
  payload: {
    descriptions,
  },
});