import React, { useContext, useState } from 'react';

import { Button, Checkbox, Col, Divider, message, Row } from 'antd';
import axios from 'axios';
import { DynamicsNavContext } from '../../dynamics-nav-utility/dynamicsNavProvider';
import {
  generateDynamicsNavTableColumns,
  getESExportKeys,
  getWebExportKeys,
} from '../../dynamics-nav-utility/dynamicsNavUtil';
import { apiUri } from '../../config/vars';

function NewExportsForm({ setNewExportView, loadExports }: any) {
  const dynamicsNav = useContext(DynamicsNavContext);
  const tableColumns = generateDynamicsNavTableColumns(dynamicsNav.elements);

  const [checkBoxes, setCheckBoxes] = useState(
    tableColumns.map((column: any) => {
      return {
        ...column,
        checked: true,
      };
    }),
  );

  const [pricelistOnly, setPricelistOnly] = useState(false);
  const [inExport2, setInExport2] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const onChangeCheckbox = (index: any, checked: any) => {
    setCheckBoxes([
      ...checkBoxes.slice(0, index),
      {
        ...checkBoxes[index],
        checked,
      },
      ...checkBoxes.slice(index + 1),
    ]);
  };

  const selectAll = () => {
    setCheckBoxes(
      checkBoxes.map((cB: any) => {
        return {
          ...cB,
          checked: true,
        };
      }),
    );
  };

  const unSelectAll = () => {
    setCheckBoxes(
      checkBoxes.map((cB: any) => {
        return {
          ...cB,
          checked: false,
        };
      }),
    );
  };

  const selectWebKeys = () => {
    setCheckBoxes(
      checkBoxes.map((cB: any) => {
        return {
          ...cB,
          checked: getWebExportKeys(dynamicsNav.elements).includes(cB.key),
        };
      }),
    );
  };

  const selectESKeys = () => {
    setCheckBoxes(
      checkBoxes.map((cB: any) => {
        return {
          ...cB,
          checked: getESExportKeys(dynamicsNav.elements).includes(cB.key),
        };
      }),
    );
  };

  const onGenerate = async () => {
    setIsGenerating(true);
    try {
      const data = {
        pricelistOnly,
        inExport2,
        columns: checkBoxes
          .filter((checkbox: any) => checkbox.checked)
          .map((checkbox: any) => checkbox.key),
      };
      await axios.post(`${apiUri}/exports`, data);
      await loadExports();

      setNewExportView(false);
    } catch (e) {
      message.error('Fout opgetreden');
      console.log(e);
    }
    setIsGenerating(false);
  };

  return (
    <div>
      <div style={{ borderBottom: '1px solid #e9e9e9', paddingBottom: 10 }}>
        <Row>
          <Col span={18}>
            <Button style={{ marginRight: 10 }} onClick={selectAll}>
              Alles selecteren
            </Button>
            <Button style={{ marginRight: 10 }} onClick={selectESKeys}>
              ES selectie
            </Button>
            <Button style={{ marginRight: 10 }} onClick={selectWebKeys}>
              Website selectie
            </Button>
            <Button onClick={unSelectAll}>Verwijder selectie</Button>
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            <Button
              disabled={isGenerating}
              loading={isGenerating}
              type="primary"
              onClick={onGenerate}
            >
              Export genereren
            </Button>
          </Col>
        </Row>
      </div>
      <br />
      <Row>
        <Col>
          <Checkbox
            value="pricelist"
            checked={pricelistOnly}
            onChange={(e: any) => setPricelistOnly(e.target.checked)}
          >
            Enkel verkorte artikelprijslijst (referenties gevormd met groene bollen)
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col>
          <Checkbox
            value="inExport2"
            checked={inExport2}
            onChange={(e: any) => setInExport2(e.target.checked)}
          >
            Gebruik configuraties in 'Export2' (standaard Export1)
          </Checkbox>
        </Col>
      </Row>
      <Divider />
      <Row>
        {checkBoxes
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((element: any, index: number) => {
            return (
              <Col key={element.key} span={12}>
                <Checkbox
                  value={element.key}
                  onChange={(e: any) => onChangeCheckbox(index, e.target.checked)}
                  checked={element.checked}
                >
                  {element.title}
                </Checkbox>
              </Col>
            );
          })}
      </Row>
    </div>
  );
}

export default NewExportsForm;
