import './App.css';
import logo from './logo.png';
import messages from './messages';

import React, { useContext, useEffect, useReducer, useState } from 'react';
import useBoolean from 'react-use/lib/useBoolean';
import { FormattedMessage } from 'react-intl';
import querystring from 'querystring';
import { Button, Layout, message, Modal, Typography } from 'antd';
import axios from 'axios';
import { default as uuidv5 } from 'uuid/v5';

import rootReducer from './core/reducers/rootReducer';
import { setAppState } from './core/actions/appActions';
import { generateDescriptions, setDescriptions } from './core/actions/descriptionActions';
import { setName } from './core/actions/nameActions';

import CategoryBlock from './components/CategoryBlock/CategoryBlock';
import DescriptionBlock from './components/DescriptionBlock/DescriptionBlock';
import StartBlock from './components/StartBlock/StartBlock';
import ProductBlock from './components/ProductBlock/ProductBlock';
import { enrichLongName } from './components/DescriptionBlock/LongDescriptions';
import { enrichShortName } from './components/DescriptionBlock/ShortDescriptions';
import StatsBlock from './components/StatsBlock/StatsBlock';
import { DynamicsNavContext } from './dynamics-nav-utility/dynamicsNavProvider';
import {
  DynamicsNavElement,
  getRequiredDynamicNavElements,
} from './dynamics-nav-utility/dynamicsNavUtil';
import { apiUri, cognitoClientID, cognitoUri } from './config/vars';

const { Header, Content, Footer } = Layout;
const { Text } = Typography;
const { confirm } = Modal;
const MAX_CHARACTERS_COUNT = 100;

const initialState = {
  name: null,
  tags: [],
  categories: [],
  descriptions: {
    line1: [],
    line2: [],
    line3: [],
    line4: [],
    line5: [],
  },
} as any;
export const AppDispatch = React.createContext(initialState);

function showConfirm(missingDynamicsNavElements: DynamicsNavElement[], onOk: any, resolve: any) {
  confirm({
    title: `Ontbrekende Dynamics Nav IDs (${missingDynamicsNavElements.length})`,
    content: (
      <div>
        <ul>
          {missingDynamicsNavElements.map(el => (
            <li>{`${el.desc} (${el.id})`}</li>
          ))}
        </ul>
      </div>
    ),
    onOk,
    onCancel() {
      resolve();
    },
    okText: 'Doorgaan',
    cancelText: 'Annuleren',
  });
}

function showDescriptionConfirm(descriptionsTooLong: any, onOk: any) {
  confirm({
    title: `Omschrijvingen opmerkingen`,
    content: (
      <div>
        <ul>
          {Object.keys(descriptionsTooLong).map((descriptionKey: any) => (
            <li>
              <FormattedMessage {...messages[descriptionKey]} /> heeft{' '}
              {descriptionsTooLong[descriptionKey]} tekens (max. {MAX_CHARACTERS_COUNT})
            </li>
          ))}
        </ul>
      </div>
    ),
    onOk,
    onCancel() {},
    okText: 'Doorgaan',
    cancelText: 'Annuleren',
  });
}

function validateDescriptions(descriptions: any) {
  const shortDescriptionCount = []
    .concat(...(Object.values(descriptions) as any))
    .map((description: any) => enrichShortName(description))
    .join('-').length;

  const shortDescriptionError =
    shortDescriptionCount > MAX_CHARACTERS_COUNT
      ? { shortDescriptions: shortDescriptionCount }
      : null;

  return Object.keys(descriptions).reduce((errors: any, line: any) => {
    const lineCount = descriptions[line]
      .map((property: any) => enrichLongName(property))
      .join(' - ').length;
    if (lineCount > MAX_CHARACTERS_COUNT) {
      return {
        ...(errors || {}),
        [line]: lineCount,
      };
    }

    return errors;
  }, shortDescriptionError);
}

const validateConfig = (
  appState: any,
  ignoredIds: any,
  requiredDynamicsNavElements: DynamicsNavElement[],
) => {
  try {
    const usedDynamicsNavIds =
      (appState.categories &&
        appState.categories.reduce((dynamicsNavIds: any[], category: any) => {
          return [
            ...dynamicsNavIds,
            ...(category.codes
              ? category.codes.reduce((codeDynamicNavIds: any[], code: any) => {
                  return [
                    ...codeDynamicNavIds,
                    ...(code.properties
                      ? code.properties.reduce((propertyDynamicNavIds: any[], property: any) => {
                          if (property.dynamicsNavId) {
                            return [...propertyDynamicNavIds, property.dynamicsNavId];
                          }
                          return propertyDynamicNavIds;
                        }, [])
                      : []),
                  ];
                }, [])
              : []),
          ];
        }, [])) ||
      [];

    const subGroups = filterSubGroups(appState);
    const subGroup: any = subGroups && subGroups[0];

    console.log(subGroup);

    console.log(ignoredIds);

    const ignoredIdsObject = subGroup ? (ignoredIds as any)[subGroup.id] || {} : {};
    console.log(ignoredIdsObject);
    const ignoredIdsArray = ignoredIdsObject.ids || [];

    return requiredDynamicsNavElements
      .filter(({ id }) => !ignoredIdsArray.includes(id))
      .filter(({ id }) => !usedDynamicsNavIds.includes(id));
  } catch (e) {
    console.error(e);
  }
};

function useAuthenticator(): boolean {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (location.search.split('=')[1]) {
      const requestBody = {
        grant_type: 'authorization_code',
        code: location.search.split('=')[1],
        client_id: cognitoClientID,
        redirect_uri: `${window.location.origin}/auth`,
      };

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      axios
        .post(`${cognitoUri}/oauth2/token`, querystring.stringify(requestBody), config)
        .then(result => {
          setIsAuthenticated(true);
          localStorage.setItem('token', JSON.stringify(result.data));
          window.history.replaceState(null, '', '/');
          // Do somthing
        })
        .catch(err => {
          console.log(err);
          // Do somthing
        });
    } else {
      const token = localStorage.getItem('token');
      if (token) {
        setIsAuthenticated(true);
      } else {
        window.location.href = `${cognitoUri}/login?response_type=code&client_id=${cognitoClientID}&redirect_uri=${window.location.origin}/auth`;
      }
    }
  }, []);

  return isAuthenticated;
}

const logout = () => {
  localStorage.removeItem('token');
  window.location.href = `${cognitoUri}/logout?response_type=code&client_id=${cognitoClientID}&redirect_uri=${window.location.origin}/auth`;
};

function groupSortFunction(a: any, b: any) {
  var p1 = a.dynamicsNavId && a.dynamicsNavId.toLowerCase();
  var p2 = b.dynamicsNavId && b.dynamicsNavId.toLowerCase();

  if (p1 > p2) return 1;
  if (p1 < p2) return -1;
  return 0;
}

const filterSubGroups = (appState: any) => {
  try {
    const subGroupsCodeArray = appState.categories.reduce(
      (categoryCodes: any, currentCategory: any) => {
        return [
          ...categoryCodes,
          ...currentCategory.codes.reduce((codeCodes: any, currentCode: any) => {
            return [
              ...codeCodes,
              ...(currentCode.properties
                ? currentCode.properties.filter(
                    (property: any) =>
                      property.dynamicsNavId === 'C_75048' || property.dynamicsNavId === 'C_75027',
                  )
                : []),
            ];
          }, []),
        ];
      },
      [],
    );

    return Object.values(
      subGroupsCodeArray.reduce((codeObj: any, { name, dynamicsNavId }: any) => {
        return {
          ...codeObj,
          [name]: {
            id: uuidv5(`${dynamicsNavId}_${name}`, uuidv5.DNS),
            name,
            dynamicsNavId,
          },
        };
      }, {}),
    ).sort(groupSortFunction);
  } catch (e) {
    console.error(e);
  }
};

const App = () => {
  const dynamicsNav = useContext(DynamicsNavContext);

  const [isStartDisabled, setStartDisabled] = useBoolean(false);
  const [isCategoriesDisabled, setCategoriesDisabled] = useBoolean(true);
  const [isDescriptionsDisabled, setDescriptionsDisabled] = useBoolean(true);
  const [isDynamicsNAVExportDisabled, setDynamicsNAVExportDisabled] = useBoolean(true);
  const [isGenerateCombinationsDisabled, setGenerateCombinationsDisabled] = useBoolean(true);
  const [isExportCombinationsDisabled, setExportCombinationsDisabled] = useBoolean(true);
  const [appState, dispatch] = useReducer(rootReducer, initialState);
  const [postData, setPostData] = useState(null);
  const [generateData, setGenerateData] = useState(null);
  const [responseData, setResponseData] = useState({
    products: [],
  });

  const postProductsData = async ({ id, ...data }: any) => {
    try {
      let response;
      if (id) {
        response = await axios.put(`${apiUri}/entries/${id}`, JSON.stringify(data));
      } else {
        response = await axios.post(`${apiUri}/entries`, JSON.stringify(data));
      }

      setPostData(null);
      if (response.data) {
        dispatch(setAppState(response.data));
      }
    } catch (e) {
      message.error('Er is een fout opgetreden');
    }
  };

  if (process.env.REACT_APP_ENV !== 'development') {
    const isAuthenticated = useAuthenticator();

    if (!isAuthenticated) {
      return <div />;
    }
  }

  const saveAndGenerate = async ({ id, ...data }: any, options: any) => {
    try {
      setGenerateData({ id, ...data });
      const query = options && options.active ? '?active=true' : '';
      await axios.put(`${apiUri}/entries/${id}`, JSON.stringify(data));

      const response = await axios.get(`${apiUri}/generate/${id}${query}`);

      const { signedUrl } = response.data;

      const s3Response = await axios.get(signedUrl);

      setResponseData({ products: s3Response.data });
      setGenerateData(null);
    } catch (e) {
      console.log(e);
      message.error('Er is een fout opgetreden.');
    }
  };

  return (
    <AppDispatch.Provider value={dispatch}>
      <Layout className="layout">
        <Header>
          <img src={logo} className="logo" alt="logo" />
          <Button
            size="small"
            icon="logout"
            style={{ float: 'right', marginTop: '20px' }}
            onClick={logout}
          >
            <FormattedMessage {...messages.logout} />
          </Button>
        </Header>
        <Content style={{ margin: '8px 16px 0' }}>
          <StatsBlock postData={postData} />
          <StartBlock
            isDisabled={isStartDisabled}
            onReset={() => {
              setStartDisabled(false);
              setCategoriesDisabled(true);
              setDescriptionsDisabled(true);
              setDynamicsNAVExportDisabled(true);
              setGenerateCombinationsDisabled(true);
              setExportCombinationsDisabled(true);
              setResponseData({
                products: [],
              });
            }}
            onNewEntry={() => {
              setStartDisabled(true);
              setCategoriesDisabled(false);
            }}
            onPreviousEntry={(entry: any) => {
              dispatch(setAppState(entry));
              setStartDisabled(true);
              setCategoriesDisabled(false);
            }}
          />
          <CategoryBlock
            isDisabled={isCategoriesDisabled}
            isDone={
              !isDescriptionsDisabled ||
              !isDynamicsNAVExportDisabled ||
              !isGenerateCombinationsDisabled ||
              !isExportCombinationsDisabled
            }
            onReset={() => {
              setStartDisabled(true);
              setCategoriesDisabled(false);
              setDescriptionsDisabled(true);
              setDynamicsNAVExportDisabled(true);
              setGenerateCombinationsDisabled(true);
              setExportCombinationsDisabled(true);
            }}
            onDone={({ restart, ignoredIds }: any) => {
              return new Promise(resolve => {
                const missingDynamicsNavElements = validateConfig(
                  appState,
                  ignoredIds,
                  getRequiredDynamicNavElements((dynamicsNav && dynamicsNav.elements) || []),
                );

                const onDoneConfirm = async (restart: boolean, resolve: any) => {
                  await postProductsData(appState);
                  setPostData(appState);
                  if (!restart) {
                    setDescriptionsDisabled(false);
                    setCategoriesDisabled(true);
                    dispatch(generateDescriptions(appState.categories));
                  } else {
                    setStartDisabled(false);
                    setDescriptionsDisabled(true);
                    setCategoriesDisabled(true);
                    setDynamicsNAVExportDisabled(true);
                    setGenerateCombinationsDisabled(true);
                    setExportCombinationsDisabled(true);
                    setResponseData({
                      products: [],
                    });
                  }
                  resolve();
                };

                if (missingDynamicsNavElements && missingDynamicsNavElements.length > 0) {
                  showConfirm(
                    missingDynamicsNavElements,
                    () => onDoneConfirm(restart, resolve),
                    resolve,
                  );
                } else {
                  onDoneConfirm(restart, resolve);
                }
              });
            }}
            categories={appState.categories}
            id={appState.id}
            tags={appState.tags}
            setName={(name: string) => dispatch(setName(name))}
            name={appState.name}
            isLoading={!!postData}
          />
          <DescriptionBlock
            isDisabled={isDescriptionsDisabled}
            isDone={
              !isDynamicsNAVExportDisabled ||
              !isGenerateCombinationsDisabled ||
              !isExportCombinationsDisabled
            }
            onReset={() => {
              setStartDisabled(true);
              setCategoriesDisabled(true);
              setDescriptionsDisabled(false);
              setDynamicsNAVExportDisabled(true);
              setGenerateCombinationsDisabled(true);
              setExportCombinationsDisabled(true);
            }}
            onDone={(options: any) => {
              const descriptionsTooLong = validateDescriptions(appState.descriptions);

              if (descriptionsTooLong) {
                showDescriptionConfirm(descriptionsTooLong, () => {
                  setDescriptionsDisabled(true);
                  setGenerateCombinationsDisabled(false);
                  saveAndGenerate(appState, options);
                });
              } else {
                setDescriptionsDisabled(true);
                setGenerateCombinationsDisabled(false);
                saveAndGenerate(appState, options);
              }
            }}
            descriptions={appState.descriptions}
            setDescriptions={(descriptions: any) => dispatch(setDescriptions(descriptions))}
          />
          <ProductBlock
            isDisabled={isGenerateCombinationsDisabled}
            isDone={!isExportCombinationsDisabled}
            onReset={() => {
              setStartDisabled(true);
              setCategoriesDisabled(true);
              setDescriptionsDisabled(true);
              setDynamicsNAVExportDisabled(true);
              setGenerateCombinationsDisabled(false);
              setExportCombinationsDisabled(true);
            }}
            onDone={() => {
              setGenerateCombinationsDisabled(true);
              setExportCombinationsDisabled(false);
              // setPostData(appState);
            }}
            onRestart={() => {
              setStartDisabled(false);
              setCategoriesDisabled(true);
              setDescriptionsDisabled(true);
              setDynamicsNAVExportDisabled(true);
              setGenerateCombinationsDisabled(true);
              setExportCombinationsDisabled(true);
              setResponseData({
                products: [],
              });
            }}
            configName={appState && appState.name}
            postData={generateData}
            responseData={responseData}
          />
        </Content>
        <Footer style={{ textAlign: 'center' }}>100%LIGHT &copy; {new Date().getFullYear()}</Footer>
      </Layout>
    </AppDispatch.Provider>
  );
};

export default App;
