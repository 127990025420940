import React, { useEffect, useState } from 'react';
import { Modal, Table, Tag, Button, Row, Col, List, Spin, Checkbox } from 'antd';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/nl';
import NewExportsForm from './NewExportsForm';
import { apiUri } from '../../config/vars';

const fetchExports = async () => {
  try {
    const { data: exports } = await axios.get(`${apiUri}/exports`);

    return exports;
  } catch (e) {
    console.log(e);
  }
};

const fetchExportDownloads = async (exportId: string) => {
  try {
    const { data: downloads } = await axios.get(`${apiUri}/exports/${exportId}`);

    return downloads;
  } catch (e) {
    console.log(e);
  }
};

function ExportsModal({ isOpen, onClose }: any) {
  const [exports, setExports] = useState(null);
  const [exportDownloads, setExportDownloads] = useState<any>({});
  const [expandedKeys, setExpandedKeys] = useState<any>([]);
  const [newExportView, setNewExportView] = useState(false);

  const loadExports = async () => {
    const exportsData = await fetchExports();

    setExports(exportsData);
  };

  useEffect(() => {
    loadExports();
  }, [isOpen]);

  const getExportDownloadsById = async (exportId: string) => {
    if (!exportDownloads[exportId]) {
      const downloads = await fetchExportDownloads(exportId);

      setExportDownloads((exportDownloads: any) => ({
        ...exportDownloads,
        [exportId]: downloads,
      }));
    }
  };

  const columns = [
    {
      title: 'Datum',
      dataIndex: 'createdAt',
      render: (value: any) => {
        return moment(value).format('LLL');
      },
      // @ts-ignore
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => {
        return a.createdAt - b.createdAt;
      },
    },
    {
      title: 'Status',
      dataIndex: 'id',
      render: (value: any, record: any) => {
        const inProgress = Object.values(record.chunks).find((chunk: any) => chunk.ready === false);

        if (inProgress) {
          return <Tag color="blue">In behandeling</Tag>;
        }

        return <Tag color="green">Klaar</Tag>;
      },
    },
  ];
  return (
    <Modal
      title={'Exports beheren'}
      visible={isOpen}
      onCancel={onClose}
      footer={null}
      width="1000px"
    >
      {newExportView ? (
        <NewExportsForm setNewExportView={setNewExportView} loadExports={loadExports} />
      ) : (
        <>
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button type="primary" onClick={() => setNewExportView(true)}>
                Nieuwe export
              </Button>
            </Col>
          </Row>
          <Table
            rowKey={(record: any) => record.id}
            pagination={{ pageSize: 25 }}
            size="small"
            dataSource={exports || []}
            // @ts-ignore
            columns={columns}
            expandedRowKeys={expandedKeys}
            expandedRowRender={(record: any) => {
              return exportDownloads[record.id] ? (
                <List
                  dataSource={exportDownloads[record.id]}
                  renderItem={(item: any) => (
                    <List.Item>
                      <a href={item.url} target="_blank">
                        {item.key.split('/').slice(-1)[0]}
                      </a>
                    </List.Item>
                  )}
                />
              ) : (
                <Spin />
              );
            }}
            expandIcon={(props: any) => {
              return (
                <Button
                  onClick={() => {
                    getExportDownloadsById(props.record.id);
                    setExpandedKeys([props.record.id]);
                  }}
                  disabled={
                    !!Object.values(props.record.chunks).find((chunk: any) => chunk.ready === false)
                  }
                >
                  Downloaden
                </Button>
              );
            }}
          />
        </>
      )}
    </Modal>
  );
}

export default ExportsModal;
