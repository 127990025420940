import { message } from 'antd';
import axios from 'axios';

export const filterOutCodesWithoutProperties = (categories: any) => {
  return categories.map((category: any) => {
    if (!category.codes) {
      return category;
    }
    return {
      ...category,
      codes: category.codes.filter((code: any) => code.properties && code.properties.length > 0),
    };
  });
};

export const generateDescriptions = (descriptions: any, categories: any) => {
  try {
    const categoriesWithoutCodesWithoutProperties = filterOutCodesWithoutProperties(categories);
    const descriptionElements = categoriesWithoutCodesWithoutProperties.reduce(
      (codes1: any, currentCategory: any) => {
        return [
          ...(currentCategory.codes
            ? currentCategory.codes.reduce((codes2: any, currentCode: any) => {
                return [
                  ...(currentCode.properties
                    ? currentCode.properties.reduce((codes3: any, currentProperty: any) => {
                        if (
                          currentProperty &&
                          currentProperty.name &&
                          currentProperty.abbreviation &&
                          currentProperty.dynamicsNavId
                        ) {
                          const code1Index = codes1.findIndex(
                            (c1: any) => c1.dynamicsNavId === currentProperty.dynamicsNavId,
                          );
                          if (code1Index > -1) {
                            codes1[code1Index] = {
                              ...codes1[code1Index],
                              ...(codes1[code1Index].name.length < currentProperty.name.length
                                ? { name: currentProperty.name }
                                : {}),
                              ...(codes1[code1Index].abbreviation.length <
                              currentProperty.abbreviation.length
                                ? { abbreviation: currentProperty.abbreviation }
                                : {}),
                            };
                            return codes3;
                          }

                          const code2Index = codes2.findIndex(
                            (c2: any) => c2.dynamicsNavId === currentProperty.dynamicsNavId,
                          );
                          if (code2Index > -1) {
                            codes2[code2Index] = {
                              ...codes2[code2Index],
                              ...(codes2[code2Index].name.length < currentProperty.name.length
                                ? { name: currentProperty.name }
                                : {}),
                              ...(codes2[code2Index].abbreviation.length <
                              currentProperty.abbreviation.length
                                ? { abbreviation: currentProperty.abbreviation }
                                : {}),
                            };
                            return codes3;
                          }

                          const code3Index = codes3.findIndex(
                            (c3: any) => c3.dynamicsNavId === currentProperty.dynamicsNavId,
                          );
                          if (code3Index > -1) {
                            codes3[code3Index] = {
                              ...codes3[code3Index],
                              ...(codes3[code3Index].name.length < currentProperty.name.length
                                ? { name: currentProperty.name }
                                : {}),
                              ...(codes3[code3Index].abbreviation.length <
                              currentProperty.abbreviation.length
                                ? { abbreviation: currentProperty.abbreviation }
                                : {}),
                            };
                            return codes3;
                          }

                          return [
                            ...codes3,
                            {
                              ...currentProperty,
                              line: 0,
                            },
                          ];
                        }
                        return codes3;
                      }, [])
                    : []),
                  ...codes2,
                ];
              }, [])
            : []),
          ...codes1,
        ];
      },
      [],
    );

    const currentDescriptionElements = Object.keys(descriptions).reduce(
      (descElements: any[], key: any) => {
        return [...descElements, ...descriptions[key]];
      },
      [],
    );

    let upUntilNow: any[] = [];

    return {
      line1: [
        ...descriptions.line1
          .map((el: any) => {
            const find = descriptionElements.find(
              (dEl: any) => el.dynamicsNavId === dEl.dynamicsNavId,
            );
            const upun = !upUntilNow.find((dEl: any) => el.dynamicsNavId === dEl.dynamicsNavId);
            upUntilNow = [...upUntilNow, find].filter(Boolean);

            return upun && find;
          })
          .filter(Boolean),
        ...descriptionElements.filter(
          (dEl: any) =>
            !currentDescriptionElements.find((el: any) => el.dynamicsNavId === dEl.dynamicsNavId),
        ),
      ],
      line2: [
        ...descriptions.line2
          .map((el: any) => {
            const find = descriptionElements.find(
              (dEl: any) => el.dynamicsNavId === dEl.dynamicsNavId,
            );
            const upun = !upUntilNow.find((dEl: any) => el.dynamicsNavId === dEl.dynamicsNavId);
            upUntilNow = [...upUntilNow, find].filter(Boolean);

            return upun && find;
          })
          .filter(Boolean),
      ],
      line3: [
        ...descriptions.line3
          .map((el: any) => {
            const find = descriptionElements.find(
              (dEl: any) => el.dynamicsNavId === dEl.dynamicsNavId,
            );
            const upun = !upUntilNow.find((dEl: any) => el.dynamicsNavId === dEl.dynamicsNavId);
            upUntilNow = [...upUntilNow, find].filter(Boolean);

            return upun && find;
          })
          .filter(Boolean),
      ],
      line4: [
        ...descriptions.line4
          .map((el: any) => {
            const find = descriptionElements.find(
              (dEl: any) => el.dynamicsNavId === dEl.dynamicsNavId,
            );
            const upun = !upUntilNow.find((dEl: any) => el.dynamicsNavId === dEl.dynamicsNavId);
            upUntilNow = [...upUntilNow, find].filter(Boolean);

            return upun && find;
          })
          .filter(Boolean),
      ],
      line5: [
        ...descriptions.line5
          .map((el: any) => {
            const find = descriptionElements.find(
              (dEl: any) => el.dynamicsNavId === dEl.dynamicsNavId,
            );
            const upun = !upUntilNow.find((dEl: any) => el.dynamicsNavId === dEl.dynamicsNavId);
            upUntilNow = [...upUntilNow, find].filter(Boolean);

            return upun && find;
          })
          .filter(Boolean),
      ],
    };
  } catch (e) {
    console.error(e);
    message.error('Er is een fout opgetreden');
    return {
      line1: [],
      line2: [],
      line3: [],
      line4: [],
      line5: [],
    };
  }
};
