interface ITypes {
  [key: string]: any;
}

const ADD_CATEGORY = 'ADD_CATEGORY';
const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
const DELETE_CATEGORY = 'DELETE_CATEGORY';

export const categoryTypes: ITypes = {
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
};

export type CategoryTypeTypes =
  | typeof ADD_CATEGORY
  | typeof UPDATE_CATEGORY
  | typeof DELETE_CATEGORY;

const ADD_CODE = 'ADD_CODE';
const UPDATE_CODE = 'UPDATE_CODE';
const DELETE_CODE = 'DELETE_CODE';
const DUPLICATE_CODE = 'DUPLICATE_CODE';
const TOGGLE_ACTIVE_CODE = 'TOGGLE_ACTIVE_CODE';

export const codeTypes: ITypes = {
  ADD_CODE,
  UPDATE_CODE,
  DELETE_CODE,
  DUPLICATE_CODE,
  TOGGLE_ACTIVE_CODE,
};

export type CodeTypeTypes =
  | typeof ADD_CODE
  | typeof UPDATE_CODE
  | typeof DELETE_CODE
  | typeof DUPLICATE_CODE
  | typeof TOGGLE_ACTIVE_CODE;

const ADD_PROPERTY = 'ADD_PROPERTY';
const UPDATE_PROPERTY = 'UPDATE_PROPERTY';
const DELETE_PROPERTY = 'DELETE_PROPERTY';

export const propertyTypes: ITypes = {
  ADD_PROPERTY,
  UPDATE_PROPERTY,
  DELETE_PROPERTY,
};

export type PropertyTypeTypes = typeof ADD_PROPERTY | typeof ADD_PROPERTY | typeof DELETE_PROPERTY;

const GENERATE_DESCRIPTIONS = 'GENERATE_DESCRIPTIONS';
const SET_DESCRIPTIONS = 'SET_DESCRIPTIONS';

export const descriptionTypes: ITypes = {
  GENERATE_DESCRIPTIONS,
  SET_DESCRIPTIONS,
};

export type DescriptionTypeTypes = typeof GENERATE_DESCRIPTIONS | typeof SET_DESCRIPTIONS;

const SET_NAME = 'SET_NAME';

export const nameTypes: ITypes = {
  SET_NAME,
};

export type NameTypeTypes = typeof SET_NAME;

const RESET_APP_STATE = 'RESET_APP_STATE';
const SET_APP_STATE = 'SET_APP_STATE';

export const appTypes: ITypes = {
  SET_APP_STATE,
  RESET_APP_STATE,
};

export type AppTypeTypes = typeof RESET_APP_STATE | typeof SET_APP_STATE;

const SET_TAGS = 'SET_TAGS';

export const tagTypes: ITypes = {
  SET_TAGS,
};

export type TagTypeTypes = typeof SET_TAGS;
