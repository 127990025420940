import React, { useState, useEffect } from 'react';
import { Popconfirm, Icon, Button, Card, Tag, Typography } from 'antd';

import LongDescriptions from './LongDescriptions';
import ShortDescriptions from './ShortDescriptions';

import { FormattedMessage } from 'react-intl';

import messages from '../../messages';

const { Text } = Typography;

type Props = {
  isDisabled: boolean;
  isDone: boolean;
  onReset: ((e?: React.MouseEvent<any, MouseEvent> | undefined) => void) | undefined;
  onDone: any;
  descriptions: any;
  setDescriptions: any;
};

export default function DescriptionBlock({
  isDone,
  isDisabled,
  onReset,
  onDone,
  descriptions,
  setDescriptions,
}: Props) {
  return (
    <Card
      size="small"
      title={
        <Text disabled={isDisabled}>
          <FormattedMessage {...messages.descriptions} />{' '}
          {isDone && <Icon theme="filled" type="check-circle" style={{ color: '#52c41a' }} />}
        </Text>
      }
      extra={
        (isDisabled && !isDone && (
          <Text disabled={isDisabled}>
            <FormattedMessage {...messages.categoriesFirst} />
          </Text>
        )) ||
        (isDone && (
          <Popconfirm
            title={<FormattedMessage {...messages.areYouSureYouWantToEditDescriptions} />}
            icon={null}
            okText={<FormattedMessage {...messages.yes} />}
            cancelText={<FormattedMessage {...messages.cancel} />}
            onConfirm={onReset}
            placement="bottomRight"
          >
            <a href="#">
              <FormattedMessage {...messages.descriptionsEdit} />
            </a>
          </Popconfirm>
        ))
      }
    >
      <LongDescriptions
        isDisabled={isDisabled}
        longDescriptions={descriptions}
        setLongDescriptions={setDescriptions}
      />
      <ShortDescriptions longDescriptions={descriptions} isDisabled={isDisabled} />
      <Button
        style={{ marginRight: 10 }}
        type="primary"
        disabled={isDisabled}
        onClick={() => onDone()}
      >
        Opslaan en alle combinaties genereren
      </Button>
      <Button type="primary" disabled={isDisabled} onClick={() => onDone({ active: true })}>
        Opslaan en prijslijst combinaties genereren
      </Button>
    </Card>
  );
}
