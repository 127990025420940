import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Statistic, Icon, Spin } from 'antd';
import axios from 'axios';
import { apiUri } from '../../config/vars';

const getStats = async () => {
  const { data: stats } = await axios.get(`${apiUri}/stats`);

  return stats;
};

interface Props {
  postData: any;
}

const StatsBlock = ({ postData }: Props) => {
  const [stats, setStats] = useState<any>({});
  useEffect(() => {
    if (postData === null) {
      console.log('get stats');
      getStats().then((stats: any) => setStats(stats));
    }
  }, [postData]);

  return (
    <Row gutter={16}>
      <Col span={8}>
        <Card>
          {(stats.totalConfigurations && (
            <Statistic
              title="Configuraties"
              value={stats.totalConfigurations || '-'}
              groupSeparator={'.'}
              // precision={2}
              // valueStyle={{ color: '#3f8600' }}
              // prefix={<Icon type="arrow-up" />}
              // suffix="%"
            />
          )) || (
            <div style={{ textAlign: 'center' }}>
              <Spin />
            </div>
          )}
        </Card>
      </Col>
      <Col span={8}>
        <Card>
          {(stats.totalCombinations && (
            <Statistic
              title="Combinaties"
              value={stats.totalCombinations || '-'}
              groupSeparator={'.'}
              // precision={2}
              // valueStyle={{ color: '#3f8600' }}
              // prefix={<Icon type="arrow-up" />}
              // suffix="%"
            />
          )) || (
            <div style={{ textAlign: 'center' }}>
              <Spin />
            </div>
          )}
        </Card>
      </Col>
      <Col span={8}>
        <Card>
          {(stats.totalCombinationsPricelist && (
            <Statistic
              title="Combinaties in prijslijst"
              value={stats.totalCombinationsPricelist || '-'}
              groupSeparator={'.'}
              // precision={2}
              // valueStyle={{ color: '#3f8600' }}
              // prefix={<Icon type="arrow-up" />}
              // suffix="%"
            />
          )) || (
            <div style={{ textAlign: 'center' }}>
              <Spin />
            </div>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default StatsBlock;
