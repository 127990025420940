import React, { useContext, useEffect, useState } from 'react';
import Qs from 'qs';
import {
  Badge,
  Button,
  Card,
  Col,
  Icon,
  Input,
  message,
  Modal,
  Popconfirm,
  Popover,
  Row,
  Spin,
  Table,
  Typography,
} from 'antd';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import axios from 'axios';

import CategoryList from './CategoryList/CategoryList';

import generalMessages from '../../messages';
import messages from './messages';
import { AppDispatch } from '../../App';
import { addProperty } from '../../core/actions/propertyActions';
import { setTags } from '../../core/actions/tagActions';
import TagList from './TagList';
import { DynamicsNavContext } from '../../dynamics-nav-utility/dynamicsNavProvider';
import { findDynamicsNavById } from '../../dynamics-nav-utility/dynamicsNavUtil';
import { apiUri } from '../../config/vars';

const { Text } = Typography;
const { Search } = Input;

function propertySortFunction(a: any, b: any) {
  var o1 = a.dynamicsNavId;
  var o2 = b.dynamicsNavId;

  var p1 = a.name && a.name.toLowerCase();
  var p2 = b.name && b.name.toLowerCase();

  if (o1 < o2) return -1;
  if (o1 > o2) return 1;
  if (p1 < p2) return -1;
  if (p1 > p2) return 1;
  return 0;
}

const calculateCombinations = (categories: any[]) =>
  categories.reduce(
    (count: number, category: any) => {
      return count * ((category.codes && category.codes.length) || 1);
    },
    categories.length > 0 && categories[0].codes && categories[0].codes.length > 0 ? 1 : 0,
  );

const onFilter = (value: any, record: any) =>
  !value ||
  (record.name && record.name.includes(value)) ||
  (record.dynamicsNavId && record.dynamicsNavId.includes(value)) ||
  (record.abbreviation && record.abbreviation.includes(value));

export const PreviousProperties = React.createContext({});

interface Props extends InjectedIntlProps {
  isDisabled: boolean;
  isDone: boolean;
  onReset: ((e?: React.MouseEvent<any, MouseEvent> | undefined) => void) | undefined;
  onDone: any;
  categories: any[];
  setName: Function;
  name?: string;
  id?: string;
  tags: string[];
  isLoading: boolean;
}

const CategoryBlock = ({
  isDisabled,
  isDone,
  onReset,
  onDone,
  intl,
  categories,
  setName,
  name,
  id,
  tags,
  isLoading,
}: Props) => {
  const dispatch = useContext(AppDispatch);
  const dynamicsNav = useContext(DynamicsNavContext);

  const [isOpen, setOpen] = useState<any>(null);
  const [prevProperties, setPrevProperties] = useState<any>(null);
  const [selectedPrevProperties, setSelectedPrevProperties] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string | null>('');
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isLoadingIgnoredIds, setIsLoadingIgnoredIds] = useState<boolean>(false);

  const rowSelection = {
    selectedRowKeys: selectedPrevProperties,
    onChange: (prevProps: any) => {
      setSelectedPrevProperties(prevProps);
    },
  };

  const tableColumns = [
    {
      title: 'Waarde',
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: any) => {
        // console.log(record);
        // return record.children ? (
        return text;
        // ) : (
        // <div style={{ width: '480px', wordWrap: 'break-word' }}>{text}</div>
        // );
      },
      filteredValue: [searchValue],
      onFilter,
    },
    {
      title: 'Afkorting',
      dataIndex: 'abbreviation',
      key: 'abbreviation',
      render: (text: any) => text || '-',
      filteredValue: [searchValue],
      onFilter,
    },
    {
      title: 'Dynamics Nav ID',
      dataIndex: 'dynamicsNavId',
      key: 'dynamicsNavId',
      render: (text: any) => {
        if (!text) return '-';
        const dynamicsNavElement = findDynamicsNavById(text, dynamicsNav.elements);
        return dynamicsNavElement ? `${text} (${dynamicsNavElement.desc})` : '-';
      },
      filteredValue: [searchValue],
      onFilter,
    },
    {
      title: 'Configuraties',
      dataIndex: 'configurations',
      key: 'configurations',
      render: (config: any, record: any) => {
        console.log(record);
        if (record.children) {
          return '-';
        }
        return config ? (
          <Popover
            content={
              <div style={{ overflowY: 'auto', maxHeight: '300px' }}>
                {config.map((configName: any) => (
                  <p>{configName}</p>
                ))}
              </div>
            }
            title="Eigenschap komt voor in volgende configuraties:"
            trigger="click"
          >
            <Button>{config.length}</Button>
          </Popover>
        ) : (
          0
        );
      },
      filteredValue: [searchValue],
      onFilter,
    },
  ];

  useEffect(() => {
    const getPrevProperties = async () => {
      const { data } = await axios.get(`${apiUri}/properties`);

      setPrevProperties(data.sort(propertySortFunction));
    };

    if (!isDisabled) {
      getPrevProperties();
    }
  }, [isDisabled]);

  const confirmPrevProperties = () => {
    const { codeIndex, categoryIndex }: any = isOpen;
    const addPrevProperties = prevProperties
      .filter((prop: any) => selectedPrevProperties.includes(prop.id))
      .map(({ id, configurations, ...rest }: any) => rest);

    const addPrevPropertiesReduced = addPrevProperties.reduce(
      (allPrevProperties: any, currentPrevProperty: any) => {
        const { children, ...rest } = currentPrevProperty;
        return [...allPrevProperties, rest, ...(children ? children : [])];
      },
      [],
    );
    addPrevPropertiesReduced.forEach((values: any) => {
      dispatch(
        addProperty({
          categoryIndex,
          codeIndex,
          values,
        }),
      );
    });
    setSelectedPrevProperties([]);
    setOpen(null);
    setSearchValue('');
  };

  const deleteProperties = async () => {
    try {
      setIsDeleting(true);
      await axios.delete(`${apiUri}/properties`, {
        params: {
          ids: JSON.stringify(selectedPrevProperties),
        },
        paramsSerializer: function(params) {
          return Qs.stringify(params, { arrayFormat: 'brackets' });
        },
      });

      setPrevProperties(
        prevProperties.filter((property: any) => !selectedPrevProperties.includes(property.id)),
      );
      setSelectedPrevProperties([]);
      message.success('De eigenschappen werden verwijderd.');
      setIsDeleting(false);
    } catch (e) {
      setIsDeleting(false);
      message.error('De eigenschappen konden niet verwijderd worden.');
    }
  };

  const cancelPrevProperties = () => {
    setSelectedPrevProperties([]);
    setOpen(null);
    setSearchValue('');
  };

  const getIgnoredIds = async () => {
    try {
      const result = await axios.get(`${apiUri}/productsubgroupcodes`);

      return result.data.reduce((ignoredIdWrapper: any, currentCode: any) => {
        return {
          ...ignoredIdWrapper,
          [currentCode.id]: {
            isSaved: true,
            ids: currentCode.ignoredIds,
          },
        };
      }, {});
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PreviousProperties.Provider value={{ setOpen }}>
      <Row>
        <Col>
          <Card
            size="small"
            title={
              <Text disabled={isDisabled}>
                <FormattedMessage {...messages.categories} />{' '}
                {isDone && <Icon theme="filled" type="check-circle" style={{ color: '#52c41a' }} />}
              </Text>
            }
            extra={
              (isDisabled && !isDone && (
                <Text disabled={isDisabled}>
                  <FormattedMessage {...messages.startFirst} />
                </Text>
              )) ||
              (isDone && (
                <Popconfirm
                  title={<FormattedMessage {...messages.areYouSureYouWantToEditCategories} />}
                  icon={null}
                  okText={<FormattedMessage {...generalMessages.yes} />}
                  cancelText={<FormattedMessage {...generalMessages.cancel} />}
                  onConfirm={onReset}
                  placement="bottomRight"
                >
                  <a href="#">
                    <FormattedMessage {...messages.categoriesEdit} />
                  </a>
                </Popconfirm>
              ))
            }
          >
            <Row>
              <Col>
                <Input
                  value={name}
                  disabled={isDisabled}
                  onChange={(e: any) => setName(e.target.value)}
                  placeholder={intl.formatMessage(generalMessages.startName)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TagList
                  isDisabled={isDisabled}
                  setTags={(tags: string[]) => dispatch(setTags(tags))}
                  tags={tags}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ padding: '15px 0' }}>
                <Text disabled={isDisabled}>
                  <FormattedMessage
                    {...messages.totalCombinations}
                    values={{
                      total: calculateCombinations(categories),
                    }}
                  />
                </Text>
              </Col>
            </Row>
            <CategoryList
              isDisabled={isDisabled}
              isDone={isDone}
              intl={intl}
              categories={categories}
            />
            <Row>
              <Col>
                <Button
                  type="primary"
                  disabled={isDisabled}
                  onClick={async () => {
                    setIsLoadingIgnoredIds(true);
                    const ignoredIds = await getIgnoredIds();
                    await onDone({ restart: false, ignoredIds });
                    setIsLoadingIgnoredIds(false);
                  }}
                  style={{ marginRight: '10px' }}
                  loading={isLoadingIgnoredIds}
                >
                  <FormattedMessage {...messages.toDescriptions} />
                </Button>
                <Button
                  type="primary"
                  disabled={isDisabled}
                  loading={isLoadingIgnoredIds}
                  onClick={async () => {
                    setIsLoadingIgnoredIds(true);
                    const ignoredIds = await getIgnoredIds();
                    await onDone({ restart: true, ignoredIds });
                    setIsLoadingIgnoredIds(false);
                  }}
                >
                  {id
                    ? 'Configuratie updaten en herbeginnen'
                    : 'Configuratie opslaan en herbeginnen'}
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Modal
          title={intl.formatMessage(messages.propertiesAutoCompleteTitle)}
          visible={!!isOpen}
          onOk={cancelPrevProperties}
          onCancel={cancelPrevProperties}
          footer={
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Popconfirm
                  title="Ben je zeker dat je de eigenschappen wil verwijderen?"
                  // icon={null}
                  okText={<FormattedMessage {...generalMessages.yes} />}
                  cancelText={<FormattedMessage {...generalMessages.cancel} />}
                  onConfirm={deleteProperties}
                  placement="topLeft"
                >
                  <Button key="deleteprop" type="danger" loading={isDeleting}>
                    Verwijder geselecteerde eigenschappen
                  </Button>
                </Popconfirm>
              </div>
              <div>
                <Button key="back" onClick={cancelPrevProperties}>
                  <FormattedMessage {...messages.propertiesAutocompleteCancel} />
                </Button>
                <div style={{ margin: '0 15px', display: 'inline-block' }}>
                  <Badge
                    count={(selectedPrevProperties && selectedPrevProperties.length) || 0}
                    style={{ backgroundColor: '#108ee9' }}
                    offset={[15, 15]}
                  >
                    <Button key="submit" type="primary" onClick={confirmPrevProperties}>
                      <FormattedMessage {...messages.propertiesAutocompleteSubmit} />
                    </Button>
                  </Badge>
                </div>
              </div>
            </div>
          }
          width={1000}
          style={{ top: 50 }}
        >
          {prevProperties === null ? (
            <div style={{ padding: '10px', textAlign: 'center' }}>
              <Spin />
            </div>
          ) : (
            <>
              {isOpen && (
                <Search
                  placeholder="Zoeken"
                  onChange={e => setSearchValue(e.target.value || '')}
                  style={{ width: 250, marginBottom: 10 }}
                />
              )}
              <Table
                rowKey="id"
                pagination={{
                  hideOnSinglePage: true,
                  pageSize: 10,
                }}
                size="small"
                dataSource={prevProperties}
                columns={tableColumns}
                rowSelection={rowSelection}
              />
            </>
          )}
        </Modal>
      </Row>
    </PreviousProperties.Provider>
  );
};

export default injectIntl(CategoryBlock);
