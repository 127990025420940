import React, { useState, useContext } from 'react';
import { InjectedIntl } from 'react-intl';
import { Card, Icon, Input, Row, Col, Select, Checkbox } from 'antd';

import messages from '../messages';
import { DynamicsNavContext } from '../../../dynamics-nav-utility/dynamicsNavProvider';
import { DynamicsNavElement } from '../../../dynamics-nav-utility/dynamicsNavUtil';

const Option = Select.Option;

type Props = {
  onDelete: () => void;
  onUpdate: (e: any) => void;
  isDisabled: boolean;
  condition: any;
  intl: InjectedIntl;
  dynamicsNavId: string;
  name: string;
  abbreviation: string;
};

const Property = ({
  dynamicsNavId,
  name,
  abbreviation,
  condition,
  onDelete,
  onUpdate,
  isDisabled,
  intl,
}: Props) => {
  const dynamicsNav = useContext(DynamicsNavContext);

  const [isConditional, setIsConditional] = useState(false);
  const onClickConditional = (e: any) => {
    if (!e.target.checked) {
      onUpdate({
        condition: undefined,
      });
    }
    setIsConditional(e.target.checked);
  };
  return (
    <Card
      {...(dynamicsNavId && dynamicsNavId === 'F_80004'
        ? { style: { backgroundColor: '#FA8072' } }
        : {})}
      {...(dynamicsNavId && dynamicsNavId === 'C_50011'
        ? { style: { backgroundColor: '#00FF00' } }
        : {})}
      {...(dynamicsNavId && dynamicsNavId === 'C_50007'
        ? { style: { backgroundColor: '#ADD8E6' } }
        : {})}
      size="small"
      title={
        <Input
          disabled={isDisabled}
          placeholder={intl.formatMessage(messages.propertyName)}
          defaultValue={name}
          onChange={e => onUpdate({ name: e.target.value })}
        />
      }
      extra={
        !isDisabled && (
          <a style={{ marginLeft: '8px' }} onClick={onDelete}>
            <Icon type="delete" />
          </a>
        )
      }
    >
      <Row>
        <Col>
          <Input
            disabled={isDisabled}
            placeholder={intl.formatMessage(messages.propertyAbbreviation)}
            defaultValue={abbreviation}
            onChange={e => onUpdate({ abbreviation: e.target.value })}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            allowClear
            defaultValue={dynamicsNavId}
            disabled={isDisabled}
            showSearch
            style={{ width: 200 }}
            placeholder={intl.formatMessage(messages.propertyDynamicsNavId)}
            optionFilterProp="children"
            onChange={dynamicsNavId => onUpdate({ dynamicsNavId })}
            filterOption={(input: any, option: any) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {dynamicsNav.elements.map(({ id, desc }: DynamicsNavElement) => (
              <Option key={id} value={id} style={{ fontSize: '10px' }}>
                {desc}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row>
        <Col>
          <Checkbox onClick={onClickConditional} defaultChecked={!!condition}>
            {' '}
            Conditioneel
          </Checkbox>
        </Col>
      </Row>
      {(!!condition || isConditional) && (
        <>
          <Row>
            <Col>
              <Select
                allowClear
                defaultValue={condition && condition.dynamicsNavId1}
                disabled={isDisabled}
                showSearch
                style={{ width: 200 }}
                placeholder="Conditie parameter 1"
                optionFilterProp="children"
                onChange={dynamicsNavId =>
                  onUpdate({ condition: { dynamicsNavId1: dynamicsNavId } })
                }
                filterOption={(input: any, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {dynamicsNav.elements.map(({ id, desc }: DynamicsNavElement) => (
                  <Option key={id} value={id} style={{ fontSize: '10px' }}>
                    {desc}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                allowClear
                defaultValue={(condition && condition.operator) || 'isEqual'}
                disabled={isDisabled}
                showSearch
                style={{ width: 200 }}
                placeholder="Operator"
                optionFilterProp="children"
                onChange={operator => onUpdate({ condition: { operator } })}
                filterOption={(input: any, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key={0} value="isEqual" style={{ fontSize: '10px' }}>
                  Is gelijk aan
                </Option>
                <Option key={0} value="isNotEqual" style={{ fontSize: '10px' }}>
                  Is niet gelijk aan
                </Option>
                <Option key={0} value="isLowerThan" style={{ fontSize: '10px' }}>
                  Is kleiner dan
                </Option>
                <Option key={0} value="isGreaterThan" style={{ fontSize: '10px' }}>
                  Is groter dan
                </Option>
              </Select>
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                allowClear
                defaultValue={condition && condition.dynamicsNavId2}
                disabled={isDisabled}
                showSearch
                style={{ width: 200 }}
                placeholder="Conditie parameter 2"
                optionFilterProp="children"
                onChange={dynamicsNavId =>
                  onUpdate({ condition: { dynamicsNavId2: dynamicsNavId } })
                }
                filterOption={(input: any, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {dynamicsNav.elements.map(({ id, desc }: { id: string; desc: string }) => (
                  <Option key={id} value={id} style={{ fontSize: '10px' }}>
                    {desc}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
};

export default Property;
