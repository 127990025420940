import React, { useEffect, useReducer, useContext, useRef } from 'react';
import { Button, Badge, Icon, Popconfirm, Input } from 'antd';
import { FormattedMessage, InjectedIntl } from 'react-intl';

import Category from './Category';

import { addCategory, deleteCategory, updateCategory } from '../../../core/actions/categoryActions';
import { resetAppState } from '../../../core/actions/appActions';

import { AppDispatch } from '../../../App';

import messages from '../messages';

import { default as styles } from './CategoryList.module.css';

type Props = {
  isDisabled: boolean;
  isDone: boolean;
  intl: InjectedIntl;
  categories: any[];
};

const CategoryList = ({ isDisabled, isDone, intl, categories }: Props) => {
  const dispatch = useContext(AppDispatch);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isDisabled && !isDone) {
      dispatch(resetAppState());
    }
  }, [isDisabled, isDone]);

  return (
    <>
      <Popconfirm
        title={
          <div>
            <p>Positie nieuwe categorie:</p>
            <Input
              ref={inputRef}
              key={categories.length}
              type="number"
              placeholder="Plaats"
              min={1}
              max={categories.length + 1}
              defaultValue={`${categories.length + 1}`}
            />
          </div>
        }
        icon={<Icon type="exclamation-circle" />}
        okText="Toevoegen"
        cancelText="Annuleren"
        onConfirm={() => {
          dispatch(
            addCategory(
              // @ts-ignore
              inputRef &&
                inputRef.current &&
                // @ts-ignore
                inputRef.current.state &&
                // @ts-ignore
                inputRef.current.state.value,
            ),
          );
        }}
        placement="bottomRight"
      >
        <Badge
          count={(categories && categories.length) || 0}
          style={{ backgroundColor: '#108ee9' }}
          offset={[15, 15]}
        >
          <Button disabled={isDisabled}>
            <Icon type="plus" />
            <FormattedMessage {...messages.categoryAdd} />
          </Button>
        </Badge>
      </Popconfirm>
      <div className={styles.wrapper}>
        {categories.map((category, index) => (
          <Category
            key={category.id}
            index={index}
            codes={category.codes}
            intl={intl}
            isDisabled={isDisabled}
            name={category.name}
            onDelete={() => dispatch(deleteCategory(index))}
            onUpdate={(values: any) =>
              dispatch(
                updateCategory({
                  categoryIndex: index,
                  values,
                }),
              )
            }
          />
        ))}
      </div>
    </>
  );
};

export default CategoryList;
