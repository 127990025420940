import React, { useState } from 'react';
import { Tag, Tooltip, Input, Icon } from 'antd';

type Props = {
  isDisabled?: boolean;
  tags: string[];
  setTags?: Function;
};

const TagList = ({ isDisabled, tags, setTags }: Props) => {
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const onConfirm = () => {
    if (value !== '') {
      setTags && setTags([...tags, value]);
      setValue('');
      setInputVisible(false);
    }
  };

  return (
    <div>
      {tags.map((tag: string, index: number) => {
        const isLongTag = tag.length > 20;
        const tagElem = (
          <Tag
            key={tag}
            onClose={() =>
              !isDisabled && setTags && setTags([...tags.slice(0, index), ...tags.slice(index + 1)])
            }
            closable={!!setTags}
          >
            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {inputVisible && (
        <Input
          // ref={this.saveInputRef}
          type="text"
          size="small"
          style={{ width: 78 }}
          value={value}
          onChange={e => setValue(e.target.value)}
          onBlur={onConfirm}
          onPressEnter={onConfirm}
        />
      )}
      {!inputVisible && setTags && (
        <Tag
          onClick={() => !isDisabled && setInputVisible(true)}
          style={{ background: '#fff', borderStyle: 'dashed' }}
        >
          <Icon type="plus" /> Tag toevoegen
        </Tag>
      )}
    </div>
  );
};

export default TagList;
