import React from 'react';
import { Modal, Tabs } from 'antd';
import ProductSubGroupTab from './ProductSubGroupTab';
import DynamicsNavIdsTab from './DynamicsNavIdsTab';

const { TabPane } = Tabs;

const SettingsModal = ({ isOpen, setOpen, ignoredIds, setIgnoredIds }: any) => {
  const [activeKey, setActiveKey] = React.useState('1');
  return (
    <Modal
      title="Instellingen"
      visible={isOpen}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      footer={null}
      width="1200px"
      style={{ top: 50 }}
    >
      <Tabs defaultActiveKey="1" onChange={setActiveKey} animated={false}>
        <TabPane tab="Product (sub)group codes" key="1">
          <ProductSubGroupTab
            isOpen={activeKey === '1'}
            ignoredIds={ignoredIds}
            setIgnoredIds={setIgnoredIds}
          />
        </TabPane>
        <TabPane tab="Dynamics Nav Ids" key="2">
          <DynamicsNavIdsTab />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default SettingsModal;
