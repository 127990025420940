import { nameTypes as types } from '../actions/types';

export interface NameState {
  name: string,
}

const reducer = (state: NameState, action: any) => {
  switch (action.type) {
    case types.SET_NAME:
      return {
        ...state,
        name: action.payload.name,
      }
    default:
      throw new Error();
  }
}

export default reducer;