import React, { useContext } from 'react';
import { FormattedMessage, InjectedIntl } from 'react-intl';

import { Button, Badge, Icon } from 'antd';

import { addProperty, deleteProperty, updateProperty } from '../../../core/actions/propertyActions';

import Property from './Property';

import messages from '../messages';

import { default as styles } from './PropertyList.module.css';
import { AppDispatch } from '../../../App';
import ButtonGroup from 'antd/lib/button/button-group';
import { PreviousProperties } from '../CategoryBlock';

type Props = {
  isDisabled: boolean;
  intl: InjectedIntl;
  codeIndex: number;
  properties?: any[];
  categoryIndex: number;
};

const PropertyList = ({ isDisabled, intl, properties, codeIndex, categoryIndex }: Props) => {
  const dispatch = useContext(AppDispatch);
  const { setOpen }: any = useContext(PreviousProperties);

  return (
    <>
      <Badge
        count={(properties && properties.length) || 0}
        style={{ backgroundColor: '#108ee9' }}
        offset={[15, 15]}
      >
        <ButtonGroup>
          <Button
            disabled={isDisabled}
            onClick={() =>
              dispatch(
                addProperty({
                  codeIndex,
                  categoryIndex,
                }),
              )
            }
          >
            <Icon type="plus" />
            <FormattedMessage {...messages.propertyAdd} />
          </Button>
          <Button
            icon="unordered-list"
            onClick={() =>
              setOpen({
                codeIndex,
                categoryIndex,
              })
            }
          />
        </ButtonGroup>
      </Badge>
      <div className={styles.wrapper}>
        {properties!.map((property, index) => (
          <Property
            key={property.id}
            intl={intl}
            abbreviation={property.abbreviation}
            condition={property.condition}
            name={property.name}
            dynamicsNavId={property.dynamicsNavId}
            isDisabled={isDisabled}
            onDelete={() =>
              dispatch(
                deleteProperty({
                  propertyIndex: index,
                  categoryIndex,
                  codeIndex,
                }),
              )
            }
            onUpdate={(values: any) =>
              dispatch(
                updateProperty({
                  propertyIndex: index,
                  categoryIndex,
                  codeIndex,
                  values,
                }),
              )
            }
          />
        ))}
      </div>
    </>
  );
};

PropertyList.defaultProps = {
  properties: [],
};

export default PropertyList;
