import { nameTypes as types } from './types';

interface SetNameAction {
  type: typeof types.SET_NAME
  payload: {
    name: string,
  }
}

export type NameActionTypes = SetNameAction;

export const setName = (name: string): SetNameAction => ({
  type: types.SET_NAME,
  payload: {
    name,
  },
});