import { defineMessages } from 'react-intl';

const messages: any = defineMessages({
  areYouSureYouWantToEditDescriptions: {
    defaultMessage: 'Ben je zeker dat je omschrijvingen wil aanpassen?',
    id: 'areYouSureYouWantToEditDescriptions',
  },
  areYouSureYouWantToGenerateCombinationsAgain: {
    defaultMessage: 'Ben je zeker dat je de combinaties opnieuw wil genereren?',
    id: 'areYouSureYouWantToGenerateCombinationsAgain',
  },
  areYouSureYouWantToRestart: {
    defaultMessage: 'Ben je zeker dat je wil herbeginnen?',
    id: 'areYouSureYouWantToRestart',
  },
  cancel: {
    defaultMessage: 'Annuleren',
    id: 'cancel',
  },
  categoriesFirst: {
    defaultMessage: 'Eerst moeten de categorieën gemaakt worden',
    id: 'categoriesFirst',
  },
  descriptions: {
    defaultMessage: 'Omschrijvingen',
    id: 'descriptions',
  },
  descriptionsEdit: {
    defaultMessage: 'Omschrijvingen aanpassen',
    id: 'descriptionsEdit',
  },
  downloadCsv: {
    defaultMessage: 'CSV downloaden',
    id: 'downloadCsv',
  },
  dynamicsNavExportFirst: {
    defaultMessage: 'Kies eerst waarmee de combinaties vergeleken moeten worden',
    id: 'dynamicsNavExportFirst',
  },
  end: {
    defaultMessage: 'Opnieuw starten',
    id: 'end',
  },
  exportCombinations: {
    defaultMessage: 'Combinaties exporteren',
    id: 'exportCombinations',
  },
  generateCombinations: {
    defaultMessage: 'Combinaties genereren',
    id: 'generateCombinations',
  },
  generateCombinationsAgain: {
    defaultMessage: 'Combinaties opnieuw genereren',
    id: 'generateCombinationsAgain',
  },
  generateCombinationsFirst: {
    defaultMessage: 'Eerst moeten de combinaties gegenereerd worden',
    id: 'generateCombinationsFirst',
  },
  logout: {
    defaultMessage: 'Afmelden',
    id: 'logout',
  },
  restart: {
    defaultMessage: 'Herbeginnen',
    id: 'restart',
  },
  start: {
    defaultMessage: 'Nieuwe configuratie starten',
    id: 'start',
  },
  startName: {
    defaultMessage: 'Naam voor de configuratie',
    id: 'startName',
  },
  previousEntry: {
    defaultMessage: 'Opgeslagen configuraties',
    id: 'startPreviousEntry',
  },
  toDynamicsNAVExport: {
    defaultMessage: 'Opslaan en naar combinaties genereren',
    id: 'toDynamicsNAVExport',
  },
  toExportCombinations: {
    defaultMessage: 'Combinaties exporteren',
    id: 'toExportCombinations',
  },
  toGenerateCombinations: {
    defaultMessage: 'Combinaties genereren',
    id: 'toGenerateCombinations',
  },
  uploadDynamicsNAVExport: {
    defaultMessage: 'Combinaties vergelijken',
    id: 'uploadDynamicsNAVExport',
  },
  uploadDynamicsNAVExportAgain: {
    defaultMessage: 'Dynamics NAV export opnieuw uploaden',
    id: 'uploadDynamicsNAVExportAgain',
  },
  welcome: {
    defaultMessage: 'Menu',
    id: 'welcome',
  },
  yes: {
    defaultMessage: 'Ja',
    id: 'yes',
  },
  shortDescriptions: {
    defaultMessage: 'Korte omschrijving',
    id: 'shortDescriptions',
  },
  longDescriptions: {
    defaultMessage: 'Lange omschrijving',
    id: 'longDescriptions',
  },
  line1: {
    defaultMessage: 'Lijn 1',
    id: 'line1',
  },
  line2: {
    defaultMessage: 'Lijn 2',
    id: 'line2',
  },
  line3: {
    defaultMessage: 'Lijn 3',
    id: 'line3',
  },
  line4: {
    defaultMessage: 'Lijn 4',
    id: 'line4',
  },
  line5: {
    defaultMessage: 'Lijn 5',
    id: 'line5',
  },
});

export default messages;
