import React from 'react';
import ReactDOM from 'react-dom';
import { LocaleProvider } from 'antd';
import nlBE from 'antd/lib/locale-provider/nl_BE';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { IntlProvider } from 'react-intl';
import Filters from './filters';
import DynamicsNavProvider from './dynamics-nav-utility/dynamicsNavProvider';

ReactDOM.render(
  <LocaleProvider locale={nlBE}>
    <IntlProvider locale="en">
      {window.location.pathname === '/filters' ? (
        <Filters />
      ) : (
        <DynamicsNavProvider>
          <App />
        </DynamicsNavProvider>
      )}
    </IntlProvider>
  </LocaleProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
