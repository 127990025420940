import { propertyTypes as types } from './types';

interface AddPropertyAction {
  type: typeof types.ADD_PROPERTY
  payload: {
    categoryIndex: number,
    codeIndex: number,
    values?: any,
  }
}

interface UpdatePropertyAction {
  type: typeof types.UPDATE_PROPERTY
  payload: {
    categoryIndex: number,
    codeIndex: number,
    propertyIndex: number,
    values: any,
  }
}

interface DeletePropertyAction {
  type: typeof types.DELETE_PROPERTY
  payload: {
    categoryIndex: number,
    codeIndex: number,
    propertyIndex: number,
  }
}

export type PropertyActionTypes = AddPropertyAction | DeletePropertyAction;

export const addProperty = ({ categoryIndex, codeIndex, values = {} }: any): AddPropertyAction => ({
  type: types.ADD_PROPERTY,
  payload: {
    categoryIndex,
    codeIndex,
    values,
  },
});

export const updateProperty = ({ categoryIndex, codeIndex, propertyIndex, values, }: any): UpdatePropertyAction => ({
  type: types.UPDATE_PROPERTY,
  payload: {
    categoryIndex,
    codeIndex,
    propertyIndex,
    values,
  },
});

export const deleteProperty = ({ categoryIndex, codeIndex, propertyIndex }: any): DeletePropertyAction => ({
  type: types.DELETE_PROPERTY,
  payload: {
    categoryIndex,
    codeIndex,
    propertyIndex,
  },
});