export interface DynamicsNavElement {
  id: string;
  desc: string;
  isReadOnly?: boolean; // not changeable in dynamics nav id tab (not shown in tab)
  isOnTheFly?: boolean; // should not be shown in dynamicsnav id dropdown
  isRequired?: boolean; // default required when calculating missing fields
  inWebExport?: boolean; // in web export
  inESExport?: boolean; // in ES export (certainly not PRICE_COEFF)
}

/**
 * Function that finds you a DynamicsNavElement in an array by using its ID
 * @param id the ID of the nav element that you are looking for
 * @param dynamicsNavElements the array of dynamicsNav elements
 */
export function findDynamicsNavById(
  id: string,
  dynamicsNavElements: DynamicsNavElement[],
): DynamicsNavElement | undefined {
  return dynamicsNavElements.find(el => el.id === id);
}

/**
 * Function that generates a table columns based on the dynamicsNavID and descriptions
 * @param dynamicsNavElements the array of dynamicsNav elements
 */
export function generateDynamicsNavTableColumns(dynamicsNavElements: DynamicsNavElement[]) {
  return dynamicsNavElements.map(el => {
    return {
      title: el.desc,
      dataIndex: el.id,
      key: el.id,
    };
  });
}

/**
 * Function that returns all the keys that should be exported when doing an 'web export'
 * @param dynamicsNavElements the array of dynamicsNav elements
 */
export function getWebExportKeys(dynamicsNavElements: DynamicsNavElement[]) {
  return dynamicsNavElements.filter(el => el.inWebExport).map(el => el.id);
}

export function getESExportKeys(dynamicsNavElements: DynamicsNavElement[]) {
  return dynamicsNavElements.filter(el => el.inESExport).map(el => el.id);
}

/**
 * Function to filter out all the Ids, that are required.
 * @param dynamicsNavElements
 */
export function getRequiredDynamicNavElements(
  dynamicsNavElements: DynamicsNavElement[],
): DynamicsNavElement[] {
  return dynamicsNavElements.filter(el => el.isRequired);
}
