import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Badge, Card, Tag, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import messages from '../../messages';

const { Text, Paragraph } = Typography;

const getListStyle = () => ({
  display: 'flex',
  minHeight: '39px',
  padding: 8,
  flex: '1',
  overflowX: 'auto' as 'auto',
});

const reorder = (list: any, startIndex: any, endIndex: any) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (
  source: any,
  destination: any,
  droppableSource: any,
  droppableDestination: any,
): any => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: any = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const prefixLongMapper = {
  C_75028: 'CEILING TUBE:',
  C_75029: 'MECHANISM:',
  C_75030: 'LIGHT TUBE:',
  C_75031: 'CONE:',
  C_75032: 'HINGE:',
  C_75033: 'KNEE:',
  C_75034: 'ADAPTOR:',
  C_75035: 'HOUSING:',
  C_75037: 'RINGS:',
  C_75038: 'EXPRESS TUBE:',
  C_75039: 'SHAKER SMALL TUBE:',
  C_75042: 'LENGTH:',
  C_75044: 'TUBE:',
  C_75045: 'COVER:',
  C_75046: 'COVER+PIN:',
  C_75047: 'BASE+TUBE:',
  C_75049: 'BRACKET:',
};

export const enrichLongName = (prop: any) => {
  if (prop.dynamicsNavId && (prefixLongMapper as any)[prop.dynamicsNavId]) {
    return `${(prefixLongMapper as any)[prop.dynamicsNavId]} ${prop.name}`;
  }
  return prop.name;
};

export default function LongDescriptions({
  isDisabled,
  longDescriptions,
  setLongDescriptions,
}: {
  isDisabled: boolean;
  longDescriptions: any;
  setLongDescriptions: any;
}) {
  function onDragEnd(result: any) {
    const { source, destination } = result;

    if (!result.destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(longDescriptions[source.droppableId], source.index, destination.index);

      setLongDescriptions({
        ...longDescriptions,
        [source.droppableId]: items,
      });
    } else {
      const result = move(
        longDescriptions[source.droppableId],
        longDescriptions[destination.droppableId],
        source,
        destination,
      );

      setLongDescriptions({ ...longDescriptions, ...result });
    }
  }

  return (
    <div style={{ marginBottom: '1em' }}>
      <Text disabled={isDisabled}>
        <FormattedMessage {...messages.longDescriptions} />
      </Text>
      <DragDropContext onDragEnd={onDragEnd}>
        {Object.keys(longDescriptions).map(key => (
          <Card key={key} size="small" style={{ marginTop: 8 }} className="droppable-inside">
            <div style={{ display: 'flex' }}>
              <Text disabled={isDisabled} strong style={{ padding: 8 }}>
                <FormattedMessage {...messages[key]} />
              </Text>
              <Droppable droppableId={key} direction="horizontal">
                {provided => (
                  <div ref={provided.innerRef} style={getListStyle()}>
                    {longDescriptions[key].map((item: any, index: number) => (
                      <Draggable
                        key={`${item.name}_${item.dynamicsNavId}`}
                        draggableId={`${item.name}_${item.dynamicsNavId}`}
                        index={index}
                        isDragDisabled={isDisabled}
                      >
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Tag className={isDisabled ? 'ant-btn disabled' : ''}>
                              {enrichLongName(item)}
                            </Tag>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <Badge
                count={
                  longDescriptions[key]
                    .map((description: any) => enrichLongName(description))
                    .join(' - ').length
                }
                overflowCount={999}
                style={{
                  backgroundColor: '#fff',
                  color: '#999',
                  boxShadow: '0 0 0 1px #d9d9d9 inset',
                  margin: 8,
                }}
              />
            </div>
          </Card>
        ))}
      </DragDropContext>
    </div>
  );
}
