const isDevelopment =
  process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENVIRONMENT === 'development';

export const cognitoUri = isDevelopment
  ? 'https://codens-100percentlight-react-dev.auth.eu-central-1.amazoncognito.com'
  : 'https://codens-100percentlight-react.auth.eu-west-1.amazoncognito.com';

export const cognitoClientID = isDevelopment
  ? '7en1j3ftjgq2u81665vmkjfcho'
  : '5v0j9pmjq9ns4tqo4p21ba99uu';

export const apiUri = isDevelopment
  ? 'https://sg6yk7r47g.execute-api.eu-west-1.amazonaws.com/dev'
  : 'https://iauuvn7leg.execute-api.eu-west-1.amazonaws.com/dev';
