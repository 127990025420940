import { tagTypes as types } from './types';

interface SetTagAction {
  type: typeof types.SET_TAGS;
  payload: string[];
}

export type TagActionTypes = SetTagAction;

export const setTags = (tags: string[]): SetTagAction => ({
  type: types.SET_TAGS,
  payload: tags,
});
